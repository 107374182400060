.header_contact

	.header_contact_wrapper
		margin-top -90px
		p
			color white
			text-align center

	.header_contact_box
		padding 30px
		padding-bottom 15px
		background-color white
		border-radius 24px
		box-shadow 0 2px 6px 0 rgba(0, 0, 0, 0.15)
		max-width 770px
		margin 0 auto

	form
		display flex
		align-items center
		justify-content center

		.form-group
			font-size 20px
			margin-right 10px
			flex 1
			&:last-child
				margin-right 0

			input
				height 42px
				background-color white
				font-size 16px
				border-radius 24px
				color colorDark
				background-color #F5F5F5
				border-color #F5F5F5

				&::-webkit-input-placeholder
					font-size 16px
				&::-moz-placeholder
					font-size 16px
				&:-ms-input-placeholder
					font-size 16px
				&:-moz-placeholder
					font-size 16px

		button
			display block
			font-size 18px
			padding 7px 40px
			font-weight bold
			margin-right 0
			width 100%

.grid_features_one_page,
.grid_features_micro_site,
.grid_features_online_store
	display grid
	grid-template-columns repeat(3, 1fr)
	column-gap 30px
	max-width 90%
	margin 60px auto
	> div
		text-align center
		img
			width 93px
			margin-bottom 30px
		p
			margin 0

.grid_features_micro_site
	grid-template-columns repeat(4, 1fr)

.grid_features_online_store
	grid-template-columns repeat(2, 1fr)

.grid_info_landing
	display grid
	grid-template-columns repeat(2, 1fr)
	align-items center
	column-gap 50px


.landing_features_banner
	padding 100px 0
	background-color colorGrayLight
	background-position right center
	background-size cover
	background-repeat no-repeat

	.landing_features_title
		font-size 2.5rem
		color colorBlueDark
		margin-bottom 30px

		small
			color colorBlueDark
			font-size 1rem
			font-weight 300
	.landing_text_mw
		max-width 400px

	&.banner_one_page
		background-image url('../img/landings/banner-one-page.jpg')
	&.banner_micro_site
		background-position top center
		background-image url('../img/landings/banner-micro-site.jpg')
	&.banner_online_store
		background-image url('../img/landings/banner-online-store.jpg')
	&.banner_email
		background-position top center
		background-image url('../img/landings/banner-email.jpg')

	.landing_features_buttons
		margin-top 2rem
		margin-bottom 2rem
		.btn
			margin-bottom 15px

.list_stars
	margin 0
	padding 0
	&.list_big
		li
			font-size 1rem
			padding-left 28px
			margin-bottom 5px
			&:before
				margin-right 15px
	li
		list-style none
		font-size 0.8rem
		padding-left 20px
		position relative
		&:before
			content '\f005'
			font-family 'FontAwesome'
			margin-right 8
			position absolute
			left 0

@media (max-width 1199px)
	.landing_features_banner
		background-position center

@media (max-width 767px)
	.header_contact

		.header_contact_wrapper
			margin-top -120px

		form
			display block

			.form-group
				width 100%
				margin-right 0

	.grid_features_one_page
		max-width 100%

	.grid_features_micro_site
		grid-template-columns repeat(2, 1fr)
		row-gap 30px

	.grid_info_landing
		grid-template-columns 1fr
		row-gap 40px
		.grid_info_landing_img
			max-width 400px
			margin 0 auto

	.landing_features_banner
		background-position left center
		&.banner_micro_site,
		&.banner_email
			background-position left top


@media (max-width 591px)
	.grid_features_one_page,
	.grid_features_micro_site,
	.grid_features_online_store
		column-gap 15px
		> div
			img
				width 70px