/*
  Docs at http://http://simpleweatherjs.com

  Look inspired by http://www.degreees.com/
  Used for demo purposes.

  Weather icon font from http://fonts.artill.de/collection/artill-weather-icons

  DO NOT hotlink the assets/font included in this demo. If you wish to use the same font icon then download it to your local assets at the link above. If you use the links below odds are at some point they will be removed and your version will break.
*/

@font-face {
    font-family: 'weather';
    src: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/artill_clean_icons-webfont.eot');
    src: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/artill_clean_icons-webfont.eot?#iefix') format('embedded-opentype'),
         url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/artill_clean_icons-webfont.woff') format('woff'),
         url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/artill_clean_icons-webfont.ttf') format('truetype'),
         url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/artill_clean_icons-webfont.svg#artill_clean_weather_iconsRg') format('svg');
    font-weight: normal;
    font-style: normal;
}


[class^="weathericon-"], [class*=" weathericon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'weather' !important;
    speak: none;
    color: #fff;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.weathericon-0:before { content: ":"; }
.weathericon-1:before { content: "p"; }
.weathericon-2:before { content: "S"; }
.weathericon-3:before { content: "Q"; }
.weathericon-4:before { content: "S"; }
.weathericon-5:before { content: "W"; }
.weathericon-6:before { content: "W"; }
.weathericon-7:before { content: "W"; }
.weathericon-8:before { content: "W"; }
.weathericon-9:before { content: "I"; }
.weathericon-10:before { content: "W"; }
.weathericon-11:before { content: "I"; }
.weathericon-12:before { content: "I"; }
.weathericon-13:before { content: "I"; }
.weathericon-14:before { content: "I"; }
.weathericon-15:before { content: "W"; }
.weathericon-16:before { content: "I"; }
.weathericon-17:before { content: "W"; }
.weathericon-18:before { content: "U"; }
.weathericon-19:before { content: "Z"; }
.weathericon-20:before { content: "Z"; }
.weathericon-21:before { content: "Z"; }
.weathericon-22:before { content: "Z"; }
.weathericon-23:before { content: "Z"; }
.weathericon-24:before { content: "E"; }
.weathericon-25:before { content: "E"; }
.weathericon-26:before { content: "3"; }
.weathericon-27:before { content: "a"; }
.weathericon-28:before { content: "A"; }
.weathericon-29:before { content: "a"; }
.weathericon-30:before { content: "A"; }
.weathericon-31:before { content: "6"; }
.weathericon-32:before { content: "1"; }
.weathericon-33:before { content: "6"; }
.weathericon-34:before { content: "1"; }
.weathericon-35:before { content: "W"; }
.weathericon-36:before { content: "1"; }
.weathericon-37:before { content: "S"; }
.weathericon-38:before { content: "S"; }
.weathericon-39:before { content: "S"; }
.weathericon-40:before { content: "M"; }
.weathericon-41:before { content: "W"; }
.weathericon-42:before { content: "I"; }
.weathericon-43:before { content: "W"; }
.weathericon-44:before { content: "a"; }
.weathericon-45:before { content: "S"; }
.weathericon-46:before { content: "U"; }
.weathericon-47:before { content: "S"; }
