.header_home
	position relative

	.main-bg
		min-height calc(100vh - 80px)
		background linear-gradient(135deg, #0A63BF 0%,#003186 100%)
		background-color #0A63BF
		background-position center
		background-repeat no-repeat
		background-size cover
		display flex
		align-items center
		flex-direction column

	.main-header
		width 100%
		padding-left 15px
		padding-right 15px
		z-index 99

		&.fix-header
			position fixed
			left 0
			top -200px
			right 0
			background linear-gradient(135deg, #0A63BF 0%,#003186 100%)
			transition top .4s
			&.fix-header--show
				top 0
			.header_home_top
				padding-top 10px
				padding-bottom 10px
				.logo
					img
						width 120px
		.logo
			margin-right 30px
			text-align left

	.underline-yellow
		border-bottom 2px solid colorYellow

	&_top
		display block
		text-align center
		padding-top 30px
		padding-bottom 30px

	.home-icons-header
		text-align left
		margin 30px 0 !important
		padding 0
		list-style none
		li
			margin-bottom 20px
			h4
				margin-bottom 0

	.number-circle
		position relative
		z-index 2
		margin-right 10px
		font-weight bold
		width 25px
		height 25px
		display inline-flex
		align-items center
		justify-content center
		&:before
			content ''
			position absolute
			top 45%
			left 50%
			transform translate(-50%, -50%)
			border-radius 50%
			border 1px solid colorDark
			width 25px
			height 25px
			display inline-block
			z-index -1

	.header_menu_right
		display flex
		justify-content flex-end
		align-items center

		.social_network
			margin-left 30px
			li
				a
					opacity 1
					transition all 0.3s linear
					.circle_icon
						width 30px
						height 30px
						box-shadow 0 2px 4px rgba(0 ,0 ,0 ,0.5)
						.fa
							font-size 1em
							margin-top 2px
							text-shadow 0 2px 4px rgba(0 ,0 ,0 ,0.5)
					&:hover
						transform scale(1.1)

	.navbar-nav
		display flex
		align-items center
		> li
			&:last-child a
				margin-right 0 !important
			> a
				&:not(.btn)
					padding-top 10px
					padding-bottom 10px
					color white
					font-size 16px
					text-shadow 0 2px 4px rgba(0 ,0 ,0 ,0.5)
					&:hover, &:focus
						background-color transparent
						opacity 0.7
				&.btn
					padding-top 0
					padding-bottom 0
					margin 0 5px
					height 40px
					line-height 40px
					&.btn-default
						&:hover
							background-color colorDefaultHover
					&.btn-primary
						&:hover
							background-color colorPrimaryHover
				&.btn-support
					display flex
					align-items center
					img
						height 26px
						vertical-align middle

	.header_main_content
		.content_main_header
			text-align center
			h1
				font-size 30px
			.content, .text
				display block

				.italic
					font-style italic

			.content
				color white

				h1
					font-size 2.2rem
					font-weight 300
					margin-bottom 30px

					small
						color white
						font-size 0.8em

				h2
					line-height 1.2

				h3
					line-height 1.3

				ul
					margin 20px 0 45px
					list-style-type none
					padding-left 0px

					li
						margin-bottom 15px

					.fa-check 
						margin-right 10px
				&-footer
					display inline-block
					max-width auto !important
					margin-top 40px
					.btn
						margin-bottom 5px
					p
						font-size 13px
						text-align center
						margin-bottom 0px
			.form
				max-width 350px
				text-align center
				margin 20px auto
				padding 20px 0

				.registry-form
					display none

					&.active
						display block

				.start-session-form
					display none

					&.active
						display block

				.btn-group
					label:first-child
						border-top-right-radius 0px
						border-bottom-left-radius 0px
						padding 4px 15px
					label:not(.active):first-child
						background colorPinkDark !important
						color white
						border-color colorPinkDark !important
					label:last-child
						padding 4px 20px
						border-bottom-right-radius 0px

					label.active:last-child
						background-color white
						border-color white
						color colorDark

					p
						margin-bottom 0px
						font-weight 400

				.line
					display flex
					margin auto
					width 88%
					border-top 1px solid colorDark
					border-top-style dashed
					height 15px

				&-container
					background-color white
					border-radius 8px
					color colorDark
					padding 20px 25px 30px

					h3
						font-weight 300
						font-size 30px
						line-height 1
						margin-bottom 20px

					form

						.input-field
							position relative

						button
							margin-top 15px
							padding 8px 50px
							font-weight 300

						.personal
							margin-top 5px
							input
								margin-top 0px
								margin-right 2px
								height 20px

							span
								font-size 13px

					p
						margin-top 5px
						font-size 14px
						font-weight 400

					.social
						justify-content space-between
						display block
						.btn
							background-color white
							border-color colorDark
							padding 8px 15px
							font-weight 300
							margin-bottom 10px
							color colorDark

						.fa
							margin-right 10px
							font-size 22px

							&-facebook-official
								color #3B5998

							&-google-plus
								color #d34836

	.main-slider
		position absolute
		top 0
		right 0
		width 100%
		z-index 0

	.header_search
		display flex
		flex-wrap wrap
		width 100%
		padding 20px 20px 35px
		max-width initial
		margin-bottom 0px

		.header_search_box
			padding 30px
			background-color white
			border-radius 24px
			box-shadow 0 2px 6px 0 rgba(0, 0, 0, 0.15)
			max-width 770px
			margin -72px auto 0 auto

		.title-header-search
			text-align center
			width 100%
			color white

		form
			display flex
			align-items center
			justify-content center

			> *
				margin-right 10px
				margin-bottom 0px

			.select2-container
				width 100%

			.select2-selection
				&--single
					height 42px
					border-radius 24px
					padding 8px 20px
					background-color #F5F5F5
					border-color #F5F5F5

				&__rendered
					font-size 16px
					color colorGray

			.input-search
				font-size 20px

				.icon-search
					color rgba(0, 0, 0, 0.23)

				input
					height 42px
					background-color white
					font-size 16px
					border-radius 24px
					color colorDark
					background-color #F5F5F5
					border-color #F5F5F5
					width 360px

					&::-webkit-input-placeholder
						font-size 16px
					&::-moz-placeholder
						font-size 16px
					&:-ms-input-placeholder
						font-size 16px
					&:-moz-placeholder
						font-size 16px

			button
				display flex
				font-size 18px
				padding 7px 40px
				font-weight bold
				text-transform uppercase
				margin-right 0

	.icon-circle-svg
		width 35px
		height 35px
		padding 8px
		border-radius 50%
		margin-right 10px
		border 1px solid white
		display inline-block
		img
			width 100%

	.home-video
		position relative
		background-image url('../img/home/bg-video-ubicaya.jpg')
		background-size cover
		background-position center
		background-repeat no-repeat
		color white
		.row
			display flex
			flex-wrap wrap
			align-items center
			padding-top 80px
			padding-bottom 80px
		.home-video--text
			h2
				font-size 38px
				margin-bottom 10px
			p
				font-size 20px

		.home-video--wrapper
			margin-bottom 30px
			img
				border-radius 6px

		h2
			color white
			margin-bottom 0px

	.home-benefits
		padding-top 100px
		padding-bottom 100px
		.benefits--box
			.panel-heading h3
				font-size 1rem
				font-family Exo,sans-serif
				font-weight 700
				text-align center
				margin-bottom 0
			.panel-body
				min-height 180px
			.panel-footer
				background-color white
				position relative
				border-color colorGrayLight
				height 40px
			.benefits--icon
				position absolute
				top -21px
				left 15px
				img
					width 45px

		.owl-carousel
			padding-left 30px
			padding-right 30px
			.owl-dots
				margin-top 30px
			.owl-nav
				.owl-prev,
				.owl-next
					absolute top 50%
					margin-top -15px
				.owl-prev
					left -15px
				.owl-next
					right -15px

	.home-plans
		margin 60px auto

		.categories
			.category
				background-size cover
				background-position 100% 50%
				background-repeat no-repeat
				height 260px
				width 200px
				position relative
				overflow hidden
				margin auto
				border-radius 5px
				.link
					display block
					width 100%
					height 100%

				&:before, &:after
					position absolute
					top 0
					right 0
					left 0
					bottom 0

				&:after
					content ''
					background-color rgba(#0054AE, 0.75)
					transition background-color 200ms ease-in-out
					transform translate3d(0,-100%,0)
					transition all 500ms ease
				&:hover
					&:after
						transform translate3d(0,0,0)
					.description
						transform translate3d(0,0,0)
						opacity 1 !important
						top 5%
						bottom 10%

				.description
					position absolute
					top 0px
					left 0px
					right 0px
					color white
					font-size 16px
					z-index 8
					opacity 0
					padding 20px
					text-align center
					transition background-color 200ms ease-in-out
					transform translate3d(0,-100%,0)
					transition all 500ms ease
					display flex
					flex-direction column
					justify-content space-evenly

					.button
						position absolute
						bottom 0px
						left 0
						right 0
						max-width 215px
						margin 0 auto

					a
						color white
	.home-appsco
		padding-top 30px
		text-align center

		img
			width 100%
			padding 0 20px
			max-width 760px

.data_box_wrapper
	display grid
	grid-template-columns repeat(3, 1fr)
	column-gap 15px
	margin-top 4rem

	.data_box
		background-color white
		box-shadow 0 2px 4px 0 rgba(0, 0, 0, 0.18)
		text-align center
		padding 10px 20px
		border-radius 8px
		span
			display block
			font-size 2.5rem
			font-weight bold
			color colorPrimary

.section-blue
	background linear-gradient(135deg, #0A63BF 0%, #003186 100%)

.grid_timeline
	display grid
	grid-template-columns repeat(2, 1fr)
	margin auto
	color white
	position relative

	.grid_timeline_info
		padding 30px
		position relative

	.grid_timeline_dot
		width 24px
		height 24px
		border 1px solid white
		position absolute
		right -12.5px
		top 50px
		border-radius 50%
		background-color colorPrimary
		z-index 2
		animation-name dotPulse
		animation-duration 10s
		animation-fill-mode both
		animation-iteration-count infinite
		&.dot_active
			background-color white

	.grid_timeline_icon
		padding 30px
		position relative
		img
			width 55px
			max-height 60px

	.grid_timeline_line
		position absolute
		width 1px
		height 100%
		background-color white
		grid-column-start 2
		grid-row-start 1
		grid-row-end 10
		// animation-name lineFilling
		// animation-duration 10s
		// animation-fill-mode both
		// animation-iteration-count infinite

@keyframes dotPulse
	0%
		background-color colorPrimary
	15%
		background-color white
	20%
		background-color colorPrimary

@keyframes lineFilling
	0%
		height 0%
	100%
		height 100%

.section-callus
	display block
	position relative
	color white
	background linear-gradient(135deg, #0A63BF 0%,#003186 100%)

	.callus-wrapper
		display flex
		justify-content center
		align-items center

	.box-whatsapp
		width 325px
		margin-right 50px
		text-align left

		.left-phone
			display flex
			align-items center
			justify-content flex-end
			padding 15px
			border 1px solid rgba(white, 80%)
			border-radius 8px
			transition all 0.2s linear

			&:hover
				transform scale(1.05)

			.fa
				font-size 66px
				margin-right 10px

		.left-number
			font-size 36px
			margin-bottom 0px
			line-height 1

			a
				color white

		.left-text
			font-size 20px
			line-height 1

	.form-callus
		.callus-header
			display flex
			align-items center
			margin-bottom 0px
			h5
				font-size 22px
				font-weight bold
				margin 0
			.icon-chat-support
				font-size 50px
				margin-right 10px

		.callus-content
			display flex
			align-items center
			flex 1
			margin 10px auto 20px auto

			.form-group, .input-group
				margin-bottom 10px
				position relative

			.form-control
				border-color white
				height 40px
				line-height 40px
				border-radius 24px

			a
				color white
				&:hover
					text-decoration underline

			.icon-chat-support
				font-size 72px
				margin-right 8px

		form
			display flex
			align-items center
			justify-content space-between
			flex-wrap wrap
			max-width 600px

			.form-group
				width 49%

			.input-group
				input, button
					height 46px

				input
					background-color white

				button
					font-size 20px
					font-weight 300

				&.personal
					.error-absolute
						top 24px
						line-height 1

					input[type=checkbox]
						height 14px
						margin-right 5px

			.form-group-submit
				display flex
				justify-content space-between
				align-items center
				width 100%

			span
				font-size 14px

			.response
				width 100%

				.alert
					margin-top 20px

.home-testimonios,
.testimonials-section
	position relative
	> .container
		padding 0 60px

	.testimonials--title
		text-align right

	p
		line-height 1
		margin-bottom 0px

	.testimonios
		position relative
		display block
		justify-content center
		padding 60px 0
		.testimonial-title
			position absolute
			bottom 15px
			left 30px
			font-weight 500
			font-size 18px
		.testimonial-link
			background-color colorDefault
			position absolute
			bottom 0
			right 0
			padding 10px 20px
			border-top-left-radius 8px
			color white
			font-weight bold
			text-transform uppercase

	.owl-carousel
		.owl-dots
			position relative
			margin-top 30px
			.owl-dot
				span
					width 47px
					height 10px
					background-color colorGrey
					border none
				&:hover
					span
						background-color rgba(#EA1D5E, 60%)
				&.active
					span
						background-color colorDefault

	.owl-item
		border-radius 8px
		overflow hidden

	.card
		background-image url('../img/bgs/home-testimonios-ubicaya.png')
		background-size contain
		background-position left center
		background-repeat no-repeat
		padding 50px 50px 30px 30px
		border-radius 8%
		color white
		margin-top 56px
		position relative
		display inline-block
		border-radius 8px
		min-height 250px

		p:first-child
			margin-right 30px
			line-height inherit
			margin-bottom 20px
			text-align right

		p:nth-child(2), p:nth-child(3)
			text-align right
			line-height inherit

		p:nth-child(3)
			font-size 14px

		&-image
			position absolute
			background-size cover
			background-position center
			background-repeat no-repeat
			border 6px solid white
			border-radius 50%
			top -55px
			left 20px
			height 101px
			width 102px

		.testimonial-bg-card
			content ' '
			position absolute
			top 0
			left 0
			right 0
			bottom 0
			width 100%
			background-color colorPrimary
			border-radius 5px
			z-index -1

.wrapper-plans
	padding 100px 0
	.wrap-flex-plans
		display flex !important
		margin 20px auto 40px

		.col-md-4
			padding 0

		.panel-plans
			box-shadow none
			border none
			border-radius 10px
			overflow hidden
			margin-top 20px
			box-shadow 0 11px 21px rgba(black, 11%) !important

			.panel-heading
				background-color colorPrimary
				color white
				font-size 32px
				font-weight 300
				line-height 1
				padding 20px
				text-align center

				img
					height 60px
					margin-right 5px

			.panel-body
				background-color white
				padding 0 30px 30px
				.list_check
					text-align left
					margin-top 20px
				.btn
					margin 15px 0

			.panel-plans--price
				display flex
				justify-content center
				color colorDark
				flex-wrap wrap
				margin 0 -30px 40px -30px
				padding 30px 15px 10px
				position relative
				span.label-type-suscription
					display block
					text-align center
					color colorGray
					font-size 0.9em
					width 100%
				span
					line-height 1
				span.currency-symbol
					font-size 18px
					margin-top 7px
					margin-right 2px
					color colorDefault
				span.price-value
					font-size 45px
					font-weight bold
					color colorDefault
					&.month-price
						display none
				span.currency
					align-self flex-end
					margin-bottom 5px
					font-size 1.4rem

			.panel-plans--slogan
				position relative
				padding-left 30px
				&:before
					content '\e908'
					font-family 'ubicaya'
					margin-right 8
					position absolute
					left 0

			.link-plan-details
				color colorDark

			&.featured-plan
				margin-top 0
				position relative
				z-index 2
				.panel-heading
					padding-top 30px
					padding-bottom 30px
				.panel-heading, .panel-body
					background-color colorDefault
					color white
				.panel-body
					padding-bottom 55px
					background-color colorPrimary
				.panel-plans--price
					color white !important
					span.currency-symbol,
					span.price-value,
					span.label-type-suscription
						color white
				.link-plan-details
					color white

// Featured Home ///////////////////
.box_featured
	color white
	overflow hidden
	border-radius 4px
	margin-bottom 30px
	min-height 210px
	display flex
	flex-wrap wrap
	&.featured_purple
		background-color colorDefault
	&.featured_gray
		background-color colorGray
	&.featured_red
		background-color colorRed
	.featured_header
		padding 0 15px
		.featured_title
			font-size 1.2rem
		p
			font-size .8rem
			margin-bottom 0
	.featured_info
		padding 10px 15px
		font-size .8rem
		display flex
		align-items center
		width 100%
		.featured_address
			width 70%
		.btn
			padding 5px 15px
			font-size .8rem
			border-color white
			box-shadow none
			&:hover
				color colorPrimary
				background-color white
	.featured_footer
		background-color rgba(255, 255, 255, .2)
		padding 10px 15px
		font-size .9rem
		width 100%
		.stars
			display inline-block
			color #FFD925
			transition all 0.35s ease 0s
			i
				margin 0 1px
		span
			float right
			margin-left 10px
		i
			font-size 1rem

.owl-plans.owl-carousel,
.owl-features.owl-carousel,
.owl-blog.owl-carousel
	display block


.content-clients
	padding 100px 0
	.title
		margin-left 30px

.grid-clients-home
	margin-bottom 60px

	.owl-carousel
		.owl-dots
			margin-top 30px
		.owl-nav
			position absolute
			width 50px
			left 415px
			bottom 0
			.owl-prev,
			.owl-next
				absolute top 50%
				margin-top -30px
			.owl-prev
				left -15px
			.owl-next
				right -15px

	.clearfix
		width 100%
	.box-client
		display flex
		flex-wrap wrap
		align-items flex-end

		.client-img
			max-width 387px
			background-color white
			padding 15px
			border 1px solid #F0EFEF
			margin-right 40px
			img
				width 100%
		.client-info
			padding-bottom 60px
			max-width 300px
			h4
				color #00245E

.grid-clients-pro
	margin-bottom 30px

	.owl-carousel
		padding-left 30px
		padding-right 30px
		.owl-dots
			margin-top 30px
		.owl-nav
			.owl-prev,
			.owl-next
				absolute top 50%
				margin-top -15px
			.owl-prev
				left -15px
			.owl-next
				right -15px

	.clearfix
		width 100%
	.box-client
		background-color white
		padding 15px
		border 1px solid #F0EFEF
		img
			width 100%

.buttons-home-mobile
	display none


@media (min-width 700px)
	.header_home
		&_top
			display flex
			align-items center

		.header_main_content
			.content_main_header

				.form
					margin auto
					.btn-group
						label:first-child
							padding 4px 30px
					&-container
						padding: 20px 35px 30px;
						.social
							display flex

							button
								margin-bottom 0px

		.home-video
			height auto
			h2
				font-size 44px
				font-weight 300

		.home-plans
			margin 90px auto

		.home-testimonios
			margin 0 auto
			padding 80px 0
			.testimonios
				.owl-stage
					display flex
				.owl-item
					flex 1

		.home-appsco
			padding 30px 0

@media (min-width 992px)
	.header_home
		.main-slider
			display block
		&_top
			.left
				display flex
				align-items center
			h4
				text-align left

		.header_main_content
			width 970px
			margin auto
			margin-bottom 100px
			.content_main_header
				text-align left
				display flex
				align-items center
				.content
					width 600px
					padding 20px 25px

					h1
						font-size 2.2rem

						small
							color white
							font-size 0.8em

					h2, h3
						font-size 1.3rem
						margin-bottom 0px
						font-weight 300
						line-height 1.5

				.form
					width 40%

	.section-callus
		display inline-flex
		width 100%

		.left
			padding 50px 50px 30px 20px
			width 50%
			float left
			text-align right
			display flex
			justify-content flex-end

			&-header
				max-width 380px
				p
					text-align right

			&-content
				width 340px
				margin 0px 0px 0px auto
				text-align center
				padding 40px 0px 15px 0px

			&-phone
				.fa
					font-size 68px
					margin-right 10px

			&-number
				font-size 40px

			&-text
				font-size 22px

			&-help
				font-size 16px
				text-align right !important

		.right
			width 50%
			padding 50px 20px 30px 50px
			display flex

			p
				text-align left

			&-header
				max-width 380px

			&-content
				margin 10px 0px 20px

			&-form
				.input-group
					button
						padding 8px 15px

@media (min-width 961px)

	.header_home .header_menu_right
		.navbar-nav > li
			> a
				&:not(.btn)
					padding 10px 8px
					font-size 14px
				&.btn
					font-size 14px
					height 35px
					line-height 35px


@media (min-width 1200px)
	.header_home
		.header_main_content
			width 100%
			max-width 1500px
			padding 20px
			.content_main_header
				.content
					h1
						font-size 2.6rem
	.header_home .header_menu_right
		.navbar-nav > li
			> a
				&:not(.btn)
					font-size 16px

@media (max-width 960px)
	.header_home_top
		justify-content center
	.header_home
		.buttons-home-mobile
			margin-bottom 30px
			.btn
				margin-bottom 0
			.dropdown-menu
				margin 0 !important
				li
					margin-bottom 0 !important
					&.divider
						margin 9px 0


@media (max-width 991px)
	.header_home
		.header_main_content
			max-width 600px
			margin 20px auto 130px
		.header_home_top
			.social_network
				display none
			.left
				.navbar-nav
					display none
		.main-slider
			display none
		.header_search
			max-width 100%
			form
				.input-search input
					width auto
					min-width 270px

		.home-testimonios
			> .container
				padding 0 30px
		.home-video .home-video--wrapper-bottom
			.home-video--left
				width 100%
				border-top-right-radius 10px
			.home-video--right
				width 100%
				padding 25px 15px
				border-radius 0
				a img
					width 45px
	.data_box_wrapper
		margin-bottom 40px
	.section-callus
		.callus-wrapper
			flex-wrap wrap
			.box-whatsapp
				width 100%
				margin 0 0 40px 0
				.left-phone
					margin 0 auto
					max-width 315px

	.benefits
		margin-top 30px

	.wrapper-plans
		.wrap-flex-plans
			flex-wrap wrap
			justify-content center

	.owl-plans.owl-carousel,
	.owl-features.owl-carousel,
	.owl-blog.owl-carousel
		padding-left 30px
		padding-right 30px
		.owl-nav
			.owl-prev,
			.owl-next
				absolute top 50%
				margin-top -15px
			.owl-prev
				left -15px
			.owl-next
				right -15px
	.owl-blog.owl-carousel
		.owl-nav
			.owl-prev
				left 0px
			.owl-next
				right 0px

	.grid-clients
		.box-client
			margin 0 !important

@media (max-width 760px)
	.header_home
		.header_search
			.header_search_box
				padding 15px
				margin-top -100px
			form
				flex-wrap wrap
				.input-search
					width 100%
					margin-bottom 15px
					margin-right 0
					input
						width 100%
				.input-city
					flex 2
				.btn
					justify-content center
					flex 1
		.header_menu_right
			display none
		.header_home_top
			display block
			.logo
				text-align center
				margin-right 0
	.buttons-home-mobile
		display block

	.content-clients
		.title
			text-align center !important
	.grid-clients
		.box-client
			display block
			.client-img
				display block
				width 100%
				margin 0 auto 30px auto
			.client-info
				width 100%
				margin 0 auto
				text-align center
		.owl-carousel .owl-nav
			position relative
			left 50%
			transform translateX(-50%)


@media (max-width 700px)
	.header.inverted
		height auto !important
		.header_top
			padding-top 8px
			padding-bottom 8px
		.logo
			text-align left
			margin-bottom 0
			img
				max-width 80px

	.header_home
		.bg-home
			height auto
			.header_menu_right
				text-align center
				.wrap_dropdown
					display block
				.dropdown
					button 
						margin-right 0
				a
					display inline-block
					margin-top 10px
		.header_search
			max-width 100% !important
		.header_main_content
			position relative
			top 0
			left 0
			transform translate(0,0)
			padding 0 30px 15px
			.title_header
				font-size 1.6rem
				text-align center
			.subtitle_header
				font-size 1rem
				margin-top 5px
			.search
				margin-top 15px
				input
					font-size .8rem
			.content_main_header .content
				h1
					font-size 1.6rem
				h2
					font-size 1.4rem
		.buttons-home-mobile
			.btn
				border-radius 24px

@media (max-width 490px)
	.header_home
		.header_search
			form
				.input-search
				.input-city
					flex auto
					width 100%
					margin-bottom 15px
					margin-right 0
				.btn
					flex auto
					width 100%
	.wrapper-plans .wrap-flex-plans .panel-plans .panel-heading
		img
			margin 0 0 10px 0

	.section-callus
		.callus-wrapper
			.form-callus
				.form-group,
				.personal
					width 100%
				.form-group-submit
					flex-wrap wrap
	.data_box_wrapper
		grid-template-columns 1fr
		row-gap 15px

	.grid_timeline .grid_timeline_info
		&.text-right
			padding-left 0
		&:not(.text-right)
			padding-right 0