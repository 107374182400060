@font-face {
  font-family: 'ubicaya';
  src:  url('../fonts/ubicaya.eot?y84cmq');
  src:  url('../fonts/ubicaya.eot?y84cmq#iefix') format('embedded-opentype'),
    url('../fonts/ubicaya.ttf?y84cmq') format('truetype'),
    url('../fonts/ubicaya.woff?y84cmq') format('woff'),
    url('../fonts/ubicaya.svg?y84cmq#ubicaya') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'ubicaya' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ubicaya' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-premium:before {
  content: "\e904";
}
.icon-arrow-up:before {
  content: "\e905";
}
.icon-book:before {
  content: "\e906";
}
.icon-car:before {
  content: "\e907";
}
.icon-check-light:before {
  content: "\e908";
}
.icon-circle-check:before {
  content: "\e909";
}
.icon-doctors:before {
  content: "\e90a";
}
.icon-location:before {
  content: "\e90b";
}
.icon-search:before {
  content: "\e90c";
}
.icon-shopping-bag:before {
  content: "\e90d";
}
.icon-store:before {
  content: "\e90e";
}
.icon-streamline:before {
  content: "\e90f";
}
.icon-ticket:before {
  content: "\e910";
}
.icon-user:before {
  content: "\e911";
}
.icon-eye:before {
  content: "\e912";
}
.icon-arrow-right:before {
  content: "\e913";
}
.icon-parking:before {
  content: "\e914";
}
.icon-truck:before {
  content: "\e915";
}
.icon-wifi:before {
  content: "\e916";
}
.icon-featured:before {
  content: "\e917";
}
.icon-galeria:before {
  content: "\e918";
}
.icon-home:before {
  content: "\e919";
}
.icon-logo:before {
  content: "\e91a";
}
.icon-mapa:before {
  content: "\e91b";
}
.icon-video:before {
  content: "\e91c";
}
.icon-left-arrow:before {
  content: "\e800";
}
.icon-phone-circle:before {
  content: "\e801";
}
.icon-office:before {
  content: "\e803";
}
.icon-chat-support:before {
  content: "\e804";
}
.icon-whatsapp:before {
  content: "\e805";
}
.icon-telephone-ring:before {
  content: "\e806";
}
.icon-map-marker:before {
  content: "\e807";
}
.icon-telephone:before {
  content: "\e808";
}
.icon-speech-bubble:before {
  content: "\e809";
}
.icon-calendar:before {
  content: "\e80a";
}
.icon-camera:before {
  content: "\e80b";
}
.icon-credit-card:before {
  content: "\e80c";
}
.icon-sticker:before {
  content: "\e80d";
}
.icon-parked-car:before {
  content: "\e80e";
}
.icon-wifi-signal:before {
  content: "\e80f";
}
.icon-truck-move:before {
  content: "\e810";
}
.icon-air:before {
  content: "\e811";
}
.icon-advertising:before {
  content: "\e812";
}
.icon-eimi:before {
  content: "\e903";
}
.icon-wheelchair:before {
  content: "\e900";
}
.icon-waiting-room:before {
  content: "\e901";
}
.icon-bathroom:before {
  content: "\e902";
}
