/* Styling Next and Prev buttons */

.owl-default
  .owl-prev,
  .owl-next
    display block
    color colorDefault
    width 30px
    height 30px
    border-radius 30px
    absolute top 50%
    margin-top -8px
    text-align center
    &:before
      font-family 'FontAwesome'
      speak none
      font-style normal
      font-weight normal
      font-variant normal
      text-transform none
      line-height 1
      line-height 30px
      font-size 28px
  .owl-prev
    left 0px
    &:before
      content '\f053'
      right 0
  .owl-next
    right 0px
    &:before
      content '\f054'
      left 0
      
  @media (min-width 800px)
    .owl-prev
      left -20px
    
    .owl-next
      right -20px

/* Styling Pagination*/
// Pagination
.owl-dots
  absolute right 0 left 0
  bottom 3px
  text-align center

.owl-dot{
  display: inline-block;
  zoom: 1;
}
.owl-dot > span{
  display: block;
  width: 14px;
  height: 14px;
  margin: 0 5px;
  border-radius: 20px;
  background: colorGrayLight;
  border: 1px solid colorGray;
}

.owl-dot.active span,
.owl-dot:hover span
  background white
