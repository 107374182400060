colorPinkDark = #EA1D5E
colorPinkDarkHover = #d81d58
colorDark = #333333
colorDarkHover = #444444
colorGray = #9B9B9B
colorGrayDark = #626262
colorGrayLight = rgba(#333333, 10%)
colorYellow = #F0D60C
colorRed = #F64D4D
colorDefault = #EA1D5E
colorDefaultHover = #d81d58
colorPrimary = #0054AE
colorPrimaryHover = #003186
colorInfo = #2C93FF
colorInfoHover = #1084FF
colorSuccess = #98ca3f
colorSuccessHover = #7CAF20
colorWarning = #FF9500
colorWarningHover = #ED8D00
colorDanger = #FF3B30
colorDangerHover = #EE2D20
colorLink = #11BFAE
colorLinkHover = #F0D60C
colorText = #333
borderColor = #999
borderColorHover = #888
colorSilver = #c0c0c0
colorBlue = #1e4163
colorBlueDark = #00245E

colorFacebook = #3b5998
colorFacebookHover = #2c508b
colorFacebookM = #0084ff
colorTwitter = #00aced
colorTwitterHover = lightness(colorTwitter, 40%)
colorGoogle = #dd4b39
colorGoogleHover = lightness(colorGoogle, 50%)
colorWhatsapp = #25d366
colorWhatsappHover = lightness(colorWhatsapp, 40%)

@import 'nib'
@import 'bootstrap.min.css'
@import 'font-awesome.min.css'
@import 'home.styl'
@import 'landings.styl'
@import 'place_detail.styl'
@import 'place_theme_1.styl'
@import 'place_theme_2.styl'
@import 'progress_bar.styl'
@import 'components/owl-carousel.styl'
@import 'components/shake.styl'
@import 'icons.css'
@import url('https://fonts.googleapis.com/css?family=Exo:300,700')
@import url('https://fonts.googleapis.com/css?family=Roboto:300,500,700')
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,600')
@import 'fonts.css'
@import 'royalslider.css'
@import 'rs-universal.css'
@import 'animate.css'
@import 'sweetalert.css'
@import 'filestyle.min.css'
@import 'timepicker.css'
@import 'flexslider.css'
@import 'magnific-popup.css'
@import 'select2.min.css'
@import 'select2-bootstrap.css'
@import 'starrr.css'
@import 'social-likes_birman.css'
@import 'weather.css'
@import 'owl.carousel.min.css'
@import 'loader.css'
@import '../libs/magnific-popup/magnific-popup.css'
@import '../libs/jquery-toast/jquery.toast.css'
@import '../libs/summernote/summernote.css'
@import '../libs/bootstrap-colorpicker/bootstrap-colorpicker.css'

html, body
	font-family 'Roboto', sans-serif
	font-weight 300
	font-size 16px
	width 100%
	height 100%
	min-width 100%
	min-height 100%
	color colorDark
	background-color white
	// background-image url('../img/pattern.png')
	// background-repeat repeat
	
*:focus
	outline none
a, a:hover, a:active, a:focus, a:visited
	outline 0
input, input:focus, textarea, textarea:focus, button, button:hover, button:active, button:focus, button:visited
	outline 0
	outline none
button::-moz-focus-inner, input::-moz-focus-inner
		border 0
button:focus
	outline 0
	outline none
	
a, button	
	transition all .2s
a:hover, a:active, a:focus, a:visited
	text-decoration none
a
	color colorPrimary
	font-weight 400
	&:hover, &:focus
		color colorPrimaryHover
	
a.unstyle-link
	color white
	&:hover
		color white
		text-decoration none

.h1, .h2, .h3, h1, h2, h3
	margin-top 0

h1, h2, h3, h4, h5, h6
	font-family 'Montserrat', sans-serif
	font-weight 600

strong
	font-weight 700

blockquote
	font-size 1.4rem
	font-style italic
	&:before
		content '“'
	&:after
		content '”'
	&:after, &:before
		font-size 1.2em
		line-height 1
		font-weight bold

// Auxiliars Class
.no-pad
	padding 0 !important
.no-mar
	margin 0 !important
.no-mar-r
	margin-right 0 !important
.no-mar-l
	margin-left 0 !important
.no-mar-bot
	margin-bottom 0 !important
.no-mar-top
	margin-top 0 !important
.mar-bot
	margin-bottom 15px !important
.mar-bot-sm
	margin-bottom 8px !important
.mar-bot-md
	margin-bottom 30px !important
.mar-bot-lg
	margin-bottom 45px !important
.mar-top-sm
	margin-top 8px !important
.mar-top
	margin-top 15px !important
.mar-top-md
	margin-top 30px !important
.mar-top-lg
	margin-top 45px !important
.mar-l
	margin-left 15px !important
.mar-l-md
	margin-left 30px !important
.mar-l-lg
	margin-left 45px !important
.mar-r
	margin-right 15px !important
.mar-r-md
	margin-right 30px !important
.mar-r-lg
	margin-right 45px !important

.bordered
	border-radius 6px !important

.text-ellipsis
	text-overflow ellipsis
	overflow hidden
	white-space nowrap

.w-100
	width 100% !important

.d-block
	display block

.d-none
	display none

.fs-0
	font-size 3rem !important
.fs-1
	font-size 2rem !important
.fs-2
	font-size 1.4rem !important
.fs-3
	font-size 1.2rem !important

.bold
	font-weight bold !important
.semibold
	font-weight 500 !important
.normal
	font-weight 400 !important
.regular
	font-weight 300 !important

.ff-roboto
	font-family 'Roboto', sans-serif
.ff-montserrat
	font-family 'Montserrat', sans-serif
.ff-exo
	font-family 'Exo', sans-serif

.title
	font-size 2rem
	line-height 1.2
	margin-top 0
	margin-bottom 20px
	position relative
	&.underline
		&:after
			content ''
			width 80px
			height 2px
			background-color colorPrimary
			position absolute
			bottom -10px
			left 50%
			transform translateX(-50%)
.title_small
	font-size 1.4rem
	line-height 1
	margin-top 0
	margin-bottom 15px
.subtitle
	font-size 1.2rem
.upper
	text-transform uppercase
.d-inline
	display inline-block !important
.decoration-none
	text-decoration none !important

.text_normal
	font-size 1rem
	font-weight 300
	line-height 1.4
.text_normal_small
	font-size .8rem
	font-weight 300
	line-height 1.4
.text_small
	font-size .8rem
	line-height 1.8
.help-text
	color colorGray
	font-weight 300
.icon_button
	font-size 1.3rem
	padding 0 2px
	margin 0 2px
.bar-separator
	padding 5px
	color colorGray
.content_gray
	font-size .9rem
	line-height 1.4
	color colorGrayDark
.colorDark
	color colorDark !important
.colorGreen
	color colorPrimary !important
.colorSuccess
	color colorSuccess !important
.colorDefault
	color colorDefault !important
.colorGray
	color colorGray !important
.colorGrayLight
	color colorGrayLight !important
.colorWarning
	color colorWarning !important
.colorDanger
	color colorDanger !important
.colorWhite
	color white	 !important

.backgroundBlue
	background-color colorBlue !important
.text-white
	color white !important
.text-left
	text-align left !important

.textSm
	font-size .8rem
.hr-sm
	margin 15px 0
.section
	padding 80px 0
.section-small
	padding 40px 0
.section_internt
	padding 40px 0 80px
.section_middle
	padding-top 80px
.section_md
	padding-top 80px
	padding-bottom 80px
.section_color_primary
	background-color colorPrimary
	color white
.section_bg
	background-size cover
	background-repeat no-repeat
	background-position center
.min-height
	min-height 300px
	display flex
	align-items center
.line_bottom
	&:after
		content ''
		width 100%
		height 1px 
		background-color colorGrayLight
		position absolute
		bottom 0
		left 50%
		transform translateX(-50%)
.no-mar
	margin 0 !important
.no-mar-r
	margin-right 0 !important
.no-mar-l
	margin-left 0 !important
.no-mar-bot
	margin-bottom 0 !important
.no-mar-top
	margin-top 0 !important
.mar-bot
	margin-bottom 15px !important
.mar-bot-md
	margin-bottom 30px !important
.mar-bot-lg
	margin-bottom 45px !important
.mar-top
	margin-top 15px !important
.mar-top-md
	margin-top 30px !important
.mar-top-lg
	margin-top 45px !important
.mar-l
	margin-right 15px !important
.mar-l-md
	margin-right 30px !important
.mar-l-lg
	margin-right 45px !important
.mar-r
	margin-right 15px !important
.mar-r-md
	margin-right 30px !important
.mar-r-lg
	margin-right 45px !important
.margin0
	margin 0 !important
.margin_30
	margin-top 30px !important
	margin-bottom 30px !important
.margin_bottom0
	margin-bottom 0px !important
.margin_bottom15
	margin-bottom 15px !important
.margin_bottom30
	margin-bottom 30px !important
.margin_bottom60
	margin-bottom 60px !important
.margin_top10
	margin-top 10px !important
.margin_top30
	margin-top 30px !important
.margin_top60
	margin-top 60px !important
.margin_right10
	margin-right 10px
.margin_right30
	margin-right 30px
.pad-l-reply
	padding-left 45px

.bg-primary
	background-color colorPrimary
.bg-default
	background-color colorDefault
.bg-yellow
	background-color colorYellow

.lead
	font-size 20px
	font-weight 300

.flex
	display flex !important
.flex_center
	display flex !important
	align-items center
.flex_center_center
	display flex
	align-items center
	justify-content center
.flex_center_end
	display flex !important
	align-items center
	justify-content flex-end
.flex_end
	display flex !important
	align-items flex-end
.flex_stretch
	display flex !important
	align-items stretch
.flex_wrap
	display flex !important
	flex-wrap wrap
	
.justify-content-between
	justify-content space-between

.display_inline
	display inline-block
.btn_total_width
	width 100%

.pad-sm
	padding 15px !important
	
#not-found 
	h2
		font-size 220px
		line-height 210px
		font-weight 500
		letter-spacing -8px
		display flex
		justify-content center
	i.fa-question-circle
		color #e0e0e0
		font-size 190px
		top 16px
		position relative
		margin-left 0.4em
	p
		text-align center
		font-size 30px
		line-height 48px
		margin-top 10px
		color #999

.btn_border_light
	position relative
	&:before
		content ''
		position absolute
		top -7px
		left -7px
		right -7px
		bottom -7px
		border 6px solid rgba(255, 255, 255, 0.3)
		border-radius 12px
	&.border_blue
		&:before
			border 6px solid rgba(1, 121, 195, 0.2)
	&.border_red
		&:before
			border 6px solid rgba(229, 36, 36, 0.2)
.btn_icon
	position relative
	padding-left 50px !important
	.icon
		position absolute
		line-height 1
		top 50%
		left 20px
		font-size 1.3em
		transform translateY(-50%)
.line_d
	position relative
	display inline-block
	width 1px
	height 30px
	margin-left 15px
	margin-right 15px
	background-color white
	margin-top -5px
	margin-bottom -10px
	cursor default			
.list_check
	margin 0
	padding 0
	&.list_big
		li
			font-size 1rem
			padding-left 28px
			margin-bottom 5px
			&:before
				margin-right 15px
	li
		list-style none
		font-size 0.8rem
		padding-left 20px
		position relative
		&.green-bull
			padding-left 35px
			&:before
				width 28px
				height 28px
				border-radius 50%
				background-color colorSuccess
				text-align center
				padding-top 3px
		&:before
			content '\e908'
			font-family 'ubicaya'
			margin-right 8
			position absolute
			left 0
.list_check_regular
	margin-bottom 30px
	padding-left 20px
	li
		list-style none
		font-size 1rem
		margin-bottom 10px
		padding-left 25px
		position relative
		&:before
			content '\f00c'
			font-family 'FontAwesome'
			color colorPrimary
			position absolute
			left 0

.toggle-password
	cursor pointer
	color colorGray

	&:hover
		color colorDark

.disable-page
	overflow hidden
	.Anuncio, .content-map, .theme1, header
		-webkit-filter blur(6px)
		filter blur(6px)

.overlay-inactive-page
	position fixed
	top 0
	left 0
	right 0
	bottom 0
	display flex
	justify-content center
	align-items center
	background-color rgba(colorPrimary, 0.90)
	z-index 9999999999

	.dialog
		text-align center
		min-width 550px
		max-width 90%

		h2
			color white
			font-size 1.4rem
			margin-bottom 2rem

// Social Share ///////////////////
.social-likes__widget
	color colorDark
.social-likes__widget_single:hover, 
.social-likes__widget_single:active, 
.social-likes__widget_single:focus, 
.social-likes__widget_active
	background-color colorGray
	border-color colorGray
	color white
.social-likes_single-w
	margin-right 10px
.social-likes__icon
	width auto
	height 30px
	margin 0
.social-likes_single .social-likes__icon
	height 20px
	width 20px
.social-likes__icon_single
	background-image none
	padding 5px 5px
	&:hover
		background-image none !important
	&:before
		content '\f1e0'
		font-family 'FontAwesome'
		margin-right 6px
.social-likes__button
	padding 5px 10px 5px 20px
	font-size 12px
	line-height 1.5
.icon_work
	display flex
	align-items center
	margin-bottom 30px
	img
		margin-right 30px

.line_white
	border-color white
.line_primary
	border-color colorPrimary
.link_white a
	color white
	&:hover
		text-decoration underline
.overlay
	position absolute
	width 100%
	height 100%
	top 0px
	left 0px
	right 0px
	background-color rgba(1, 121, 195, 0.6)	
hr
	margin-top 30px
	margin-bottom 30px
	border-color colorGrayLight
hr.middle
	margin-top 15px
	margin-bottom 15px
.icon_badge
	position relative

.badge-success
	background-color colorSuccess

.badge_small
	background-color colorDefault
	color white
	font-size .6rem
	font-weight 300
	text-align center
	border-radius 50%
	width 12px
	height 12px
	display inline-block
	&.badge_danger
		background-color colorDanger
.note-toolbar
	text-align left !important
		
// Popover /////////////
.popover-content
	font-weight 300
	
// Buttons //////////////////////////////////
.btn
	font-size 1rem
	border-radius 6px
	padding 8px 20px
	font-weight 500
	// box-shadow inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05)
	box-shadow none
.btn-lg,
.btn-group-lg > .btn
	padding 10px 16px
	font-size 18px
	line-height 1.3333333
	border-radius 6px
.btn-sm,
.btn-group-sm > .btn
	padding 5px 10px
	font-size 12px
	line-height 1.5
	border-radius 3px
.btn-xs,
.btn-group-xs > .btn
	padding 1px 5px
	font-size 12px
	line-height 1.5
	border-radius 3px

.btn-rounded
	border-radius 24px
	padding-left 25px
	padding-right 25px

.input-group
	.btn
		padding 6px 12px
		font-size 14px
		font-weight 400
		line-height 1.42857143

// btn Light
.btn-light
	color white
	background-color transparent
	border-color white
	&:focus, &.focus, &:hover
		color white
		background-color transparent
		border-color white
.btn-light:active,
.btn-light.active,
.open > .dropdown-toggle.btn-light
	color white
	background-color transparent
	border-color white
.btn-light:active:hover,
.btn-light.active:hover,
.open > .dropdown-toggle.btn-light:hover,
.btn-light:active:focus,
.btn-light.active:focus,
.open > .dropdown-toggle.btn-light:focus,
.btn-light:active.focus,
.btn-light.active.focus,
.open > .dropdown-toggle.btn-light.focus
	color white
	background-color transparent
	border-color white

// Btn LigthGray	
.btn-light-gray
	color colorGray
	background-color transparent
	border-color colorGray
	&:focus, &.focus, &:hover
		color white
		background-color colorGray
		border-color colorGray
.btn-light-gray:active,
.btn-light-gray.active,
.open > .dropdown-toggle.btn-light-gray
	color white
	background-color colorGray
	border-color colorGray
.btn-light-gray:active:hover,
.btn-light-gray.active:hover,
.open > .dropdown-toggle.btn-light-gray:hover,
.btn-light-gray:active:focus,
.btn-light-gray.active:focus,
.open > .dropdown-toggle.btn-light-gray:focus,
.btn-light-gray:active.focus,
.btn-light-gray.active.focus,
.open > .dropdown-toggle.btn-light-gray.focus
	color white
	background-color colorGray
	border-color colorGray

// Btn Ligth Dark
.btn-light-dark
	color colorDark
	background-color transparent
	border-color colorDark
	&:focus, &.focus, &:hover
		color white
		background-color colorDark
		border-color colorDark
.btn-light-gray:active,
.btn-light-gray.active,
.open > .dropdown-toggle.btn-light-gray
	color white
	background-color colorDark
	border-color colorDark
.btn-light-gray:active:hover,
.btn-light-gray.active:hover,
.open > .dropdown-toggle.btn-light-gray:hover,
.btn-light-gray:active:focus,
.btn-light-gray.active:focus,
.open > .dropdown-toggle.btn-light-gray:focus,
.btn-light-gray:active.focus,
.btn-light-gray.active.focus,
.open > .dropdown-toggle.btn-light-gray.focus
	color white
	background-color colorDark
	border-color colorDark

// btn Default
.btn-default
	color white
	background-color colorDefault
	border-color colorDefault
	&:focus, &.focus, &:active, &:hover
		color white
		background-color colorDefaultHover
		border-color colorDefaultHover
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default
	color white
	background-color colorDefaultHover
	border-color colorDefaultHover
.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus
	color white
	background-color colorDefaultHover
	border-color colorDefaultHover
	
// btn Primary
.btn-primary
	color white
	// background linear-gradient(to bottom, colorPrimaryHover, colorPrimary)
	background-color colorPrimary
	// box-shadow inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05)
	border-color colorPrimary
	&:focus, &.focus, &:hover
		color white
		background colorPrimaryHover
		// transition background 0.1s linear
		border-color colorPrimaryHover
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary
	color white
	background-color colorPrimaryHover
	border-color colorPrimaryHover
.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus
	color white
	background-color colorPrimaryHover
	border-color colorPrimaryHover

// btn Success
.btn-success
	color white
	background-color colorSuccess
	border-color colorSuccess
	&:focus, &.focus, &:hover
		color white
		background-color colorSuccessHover
		border-color colorSuccessHover
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success
	color white
	background-color colorSuccessHover
	border-color colorSuccessHover
.btn-success:active:hover,
.btn-success.active:hover,
.open > .dropdown-toggle.btn-success:hover,
.btn-success:active:focus,
.btn-success.active:focus,
.open > .dropdown-toggle.btn-success:focus,
.btn-success:active.focus,
.btn-success.active.focus,
.open > .dropdown-toggle.btn-success.focus
	color white
	background-color colorSuccessHover
	border-color colorSuccessHover

// btn Info
.btn-info
	color white
	background-color colorInfo
	border-color colorInfo
	&:focus, &.focus, &:hover
		color white
		background-color colorInfoHover
		border-color colorInfoHover
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info
	color white
	background-color colorInfoHover
	border-color colorInfoHover
.btn-info:active:hover,
.btn-info.active:hover,
.open > .dropdown-toggle.btn-info:hover,
.btn-info:active:focus,
.btn-info.active:focus,
.open > .dropdown-toggle.btn-info:focus,
.btn-info:active.focus,
.btn-info.active.focus,
.open > .dropdown-toggle.btn-info.focus
	color white
	background-color colorInfoHover
	border-color colorInfoHover

// btn Warning
.btn-warning
	color white
	background-color colorWarning
	border-color colorWarning
	&:focus, &.focus, &:hover
		color white
		background-color colorWarningHover
		border-color colorWarningHover
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning
	color white
	background-color colorWarningHover
	border-color colorWarningHover
.btn-warning:active:hover,
.btn-warning.active:hover,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning:active:focus,
.btn-warning.active:focus,
.open > .dropdown-toggle.btn-warning:focus,
.btn-warning:active.focus,
.btn-warning.active.focus,
.open > .dropdown-toggle.btn-warning.focus
	color white
	background-color colorWarningHover
	border-color colorWarningHover

// btn Danger
.btn-danger
	color white
	background-color colorDanger
	border-color colorDanger
	&:focus, &.focus, &:hover
		color white
		background-color colorDangerHover
		border-color colorDangerHover
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger
	color white
	background-color colorDangerHover
	border-color colorDangerHover
.btn-danger:active:hover,
.btn-danger.active:hover,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger:active:focus,
.btn-danger.active:focus,
.open > .dropdown-toggle.btn-danger:focus,
.btn-danger:active.focus,
.btn-danger.active.focus,
.open > .dropdown-toggle.btn-danger.focus
	color white
	background-color colorDangerHover
	border-color colorDangerHover

// btn Pink dark
.btn-pink-dark
	color white
	background linear-gradient(to bottom, #ED457B, #ea1d5e)
	background-color #ea1d5e
	box-shadow inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05)
	border-color colorPinkDark
	&:focus, &.focus, &:hover
		color white
		background #ea1d5e
		transition background 0.1s linear
		border-color colorPinkDark
.btn-pink-dark:active,
.btn-pink-dark.active,
.open > .dropdown-toggle.btn-pink-dark
	color white
	background-color colorPinkDarkHover
	border-color colorPinkDarkHover
.btn-pink-dark:active:hover,
.btn-pink-dark.active:hover,
.open > .dropdown-toggle.btn-pink-dark:hover,
.btn-pink-dark:active:focus,
.btn-pink-dark.active:focus,
.open > .dropdown-toggle.btn-pink-dark:focus,
.btn-pink-dark:active.focus,
.btn-pink-dark.active.focus,
.open > .dropdown-toggle.btn-pink-dark.focus
	color white
	background-color colorPinkDarkHover
	border-color colorPinkDarkHover

// btn Pink dark
.btn-white
	color colorDark
	background-color white
	background linear-gradient(to bottom, #ffffff, #f9f9f9)
	background-repeat repeat-x
	box-shadow inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05)
	border-color #eee
	&:focus, &.focus, &:hover
		color colorDark
		background-color #f9f9f9
		background-position 0 -15px
		transition background-position 0.1s linear
		border-color #eee
.btn-white:active,
.btn-white.active,
.open > .dropdown-toggle.btn-white
	color colorDark
	background-color #f9f9f9
	background-position 0 -15px
	transition background-position 0.1s linear
	border-color #eee
.btn-white:active:hover,
.btn-white.active:hover,
.open > .dropdown-toggle.btn-white:hover,
.btn-white:active:focus,
.btn-white.active:focus,
.open > .dropdown-toggle.btn-white:focus,
.btn-white:active.focus,
.btn-white.active.focus,
.open > .dropdown-toggle.btn-white.focus
	color colorDark
	background-color #f9f9f9
	background-position 0 -15px
	transition background-position 0.1s linear
	border-color #eee

// btn siver
.btn-silver
	color white
	background-color colorSilver
	border-color colorSilver
	&:focus, &.focus, &:hover
		color white
		background-color colorSilver
		border-color colorSilver
.btn-silver:active,
.btn-silver.active,
.open > .dropdown-toggle.btn-silver
	color white
	background-color colorSilver
	border-color colorSilver
.btn-silver:active:hover,
.btn-silver.active:hover,
.open > .dropdown-toggle.btn-silver:hover,
.btn-silver:active:focus,
.btn-silver.active:focus,
.open > .dropdown-toggle.btn-silver:focus,
.btn-silver:active.focus,
.btn-silver.active.focus,
.open > .dropdown-toggle.btn-silver.focus
	color white
	background-color colorSilver
	border-color colorSilver

// btn blue
.btn-blue
	color white
	background-color colorBlue
	border-color colorBlue
	&:focus, &.focus, &:hover
		color white
		background-color colorBlue
		border-color colorBlue
.btn-blue:active,
.btn-blue.active,
.open > .dropdown-toggle.btn-blue
	color white
	background-color colorBlue
	border-color colorBlue
.btn-blue:active:hover,
.btn-blue.active:hover,
.open > .dropdown-toggle.btn-blue:hover,
.btn-blue:active:focus,
.btn-blue.active:focus,
.open > .dropdown-toggle.btn-blue:focus,
.btn-blue:active.focus,
.btn-blue.active.focus,
.open > .dropdown-toggle.btn-blue.focus
	color white
	background-color colorBlue
	border-color colorBlue

// btn blue
.btn-white-blue
	color colorPrimary
	background-color white
	border-color white
	&:focus, &.focus, &:hover
		color colorPrimary
		background-color white
		border-color colorPrimary
.btn-white-blue:active,
.btn-white-blue.active,
.open > .dropdown-toggle.btn-white-blue
	color colorPrimary
	background-color white
	border-color colorPrimary
.btn-white-blue:active:hover,
.btn-white-blue.active:hover,
.open > .dropdown-toggle.btn-white-blue:hover,
.btn-white-blue:active:focus,
.btn-white-blue.active:focus,
.open > .dropdown-toggle.btn-white-blue:focus,
.btn-white-blue:active.focus,
.btn-white-blue.active.focus,
.open > .dropdown-toggle.btn-white-blue.focus
	color colorPrimary
	background-color white
	border-color colorPrimary

// btn Link
.btn-link
	color colorLink
	&:hover, &:focus
		color colorLinkHover
		text-decoration none

.btn-facebook
	background-color colorFacebook
	border-color colorFacebook
	color white
	white-space nowrap
	.fa
		margin-right .6rem
	&:hover, &:focus
		background-color colorFacebookHover
		border-color colorFacebookHover
		color white

.btn-whatsapp
	background-color colorWhatsapp
	border-color colorWhatsapp
	color white
	white-space nowrap
	box-shadow inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05)
	.fa
		margin-right .6rem
	&:hover, &:focus
		background-color colorWhatsappHover
		border-color colorWhatsappHover
		color white

.btn-dark
	background-color colorDark
	border-color colorDark
	color white
	white-space nowrap
	box-shadow inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05)
	.fa
		margin-right .6rem
	&:hover, &:focus
		background-color colorDarkHover
		border-color colorDarkHover
		color white

.btn-google
	display inline-flex
	align-items center
	justify-content center
	color #868e96
	background-color transparent
	background-image none
	border-color #868e96
	white-space nowrap
	.icon-google
		margin-right .6rem
	&:hover,&:focus,&:visited
		background-color colorLight
		color #868e96
		border-color #868e96
		
.dropdown-menu > li > a
	font-weight 300

.line-o
	margin-top 1rem
	margin-bottom 1rem
	> div
		overflow hidden
		text-align center
		> span
			position relative
			padding 16px
			&:before, &:after
				content ''
				position absolute
				border-bottom 1px solid #e4e4e4
				top 50%
				width 5000px
			&:before
				right 100%
			&:after
				left 100%
			> span
				margin 0px
				word-wrap break-word
				font-size 15px
				line-height 18px
				letter-spacing 0.2px
				padding-top 0px
				padding-bottom 0px
				display inline
	


// tables color row ////////////
.unread
	td
		background-color rgba(colorPrimary, 0.8) !important
		color white !important
		font-weight 500 !important
	&:hover
		td
			background-color rgba(colorPrimary, 1) !important

.clickable-row
	cursor pointer

// Labels //////////////////////////////////
.label-default
	background-color colorDefault
.label-primary
	background-color colorPrimary
.label-success
	background-color colorSuccess
.label-info
	background-color colorInfo
.label-warning
	background-color colorWarning
.label-danger
	background-color colorDanger
.label-grey
	background-color colorGray
	
// Nav Pills //////////////////////////////////
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus
	background-color colorPrimary

// Alerts //////////////////////////////////
.alert
	strong
		font-weight 900
	a
		color colorDark
		font-weight bold
		text-decoration none
		&:hover
			text-decoration underline
	&.alert-sm
		padding 2px 10px
		margin-bottom 10px
		line-height 1.2
// .alert-success
// 	color white
// 	background-color colorSuccess
// 	border-color colorSuccess
// .alert-info
// 	color white
// 	background-color colorInfo
// 	border-color colorInfo
// .alert-warning
// 	color white
// 	background-color colorWarning
// 	border-color colorWarning
// .alert-danger
// 	color white
// 	background-color colorDanger
// 	border-color colorDanger

.alert .errorlist li
	color #a94442 !important
	
// Progress Bar ///////////////////////////////
.progress-bar
	background-color colorPrimary
.progress-bar-success
	background-color colorSuccess
.progress-bar-info
	background-color colorInfo
.progress-bar-warning
	background-color colorWarning
.progress-bar-danger
	background-color colorDanger
	
// Tables ///////////////////////////////////
.table > tbody > tr > td, 
.table > tbody > tr > th, 
.table > tfoot > tr > td, 
.table > tfoot > tr > th, 
.table > thead > tr > td, 
.table > thead > tr > th
	vertical-align middle
	
// List Group ///////////////////////////////
.list-group-item.active, 
.list-group-item.active:focus, 
.list-group-item.active:hover
	background-color colorPrimary
	border-color colorPrimary
	
// Panels ///////////////////////////////
.panel
	border-radius 6px
	border 1px solid #eaeff5
	box-shadow 0 0 10px 1px rgba(71, 85, 95, .08)

.panel-default > .panel-heading + .panel-collapse > .panel-body
	border-top 0
	
.panel.panel-payment
	.panel-heading
		padding 15px 20px
		background-color colorPrimary
		background linear-gradient(to bottom, colorPrimary, colorPrimaryHover)
		background-repeat repeat-x
		box-shadow inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05)
		color white
		input[type="radio"]
			opacity 0

.panel-group .panel
	border-radius 6px
.panel-heading
	border-top-left-radius 6px
	border-top-right-radius 6px

.panel
	.panel-heading
		font-family 'Exo', sans-serif
		font-weight bold
		border none
		padding 10px 20px
		text-align center
		.checkbox
			margin 0
		&.heading-left
			text-align left
		&.title-heading
			margin 0
			font-size 1.2rem
			font-weight 300
			text-align left
	.panel-space
		padding 15px
.panel-body
	padding  0
.panel-weather
	background-color colorInfo
	color white
.panel-pyp
	background-color #F64D4D
	color white
.panel-default
	> .panel-heading
		background-color white
		color colorPrimary
		border-bottom 1px solid colorGrayLight
.panel-primary
	> .panel-heading
		color white
		background-color colorPrimary
.panel-success
	border-color colorSuccess
	> .panel-heading
		color white
		background-color colorSuccess
.panel-info
	border-color colorInfo
	> .panel-heading
		color white
		background-color colorInfo
.panel-warning
	border-color colorWarning
	> .panel-heading
		color white
		background-color colorWarning
.panel-danger
	border-color colorDanger
	> .panel-heading
		color white
		background-color colorDanger
.panel-black
	border-color #2F2D2D
	> .panel-heading
		color white
		background-color #2F2D2D
		
.panel-blue
	background-color colorBlue
	color white
		
// Pagination /////////////////////////////////
.pagination > li > a, .pagination > li > span
	color colorPrimary
	border-radius 0 !important
	&:hover, &:focus, &:active
		background-color colorRedMain
		color white
		border-color colorRedMain
.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover
	background-color colorPrimary
	border-color colorPrimary
	border-radius 0 !important

// Flexslider Nav /////////////////////////////////
.flexslider
	margin-bottom 0
#sliderMain
	width 80%
	float left
#carouselSlider
	float left
	width 20%
	max-height 409px
	overflow hidden
	.slides
		li
			float none !important
			margin-bottom 5px
.flex-direction-nav a
	color white
.flex-direction-nav a:hover, .flex-direction-nav a:focus
	color colorPrimary
	border-color colorPrimary
	
// Nav tabs header /////////////////////////////////
.nav-tabs
	color white
	border none
	margin-bottom 15px
	> li
		margin-bottom 0
		position relative
		> a
			padding 10px 30px
			border-radius 4px
			border none
			margin-right 5px
			color colorDark
			text-align center
			background-color colorGrayLight
			&:hover
				background-color colorPrimary
				color white
		&.active
			a
				border none
				color white
				background-color colorPrimary
				&:hover, &:focus, &:active
					border none
					background-color colorPrimary
					color white
				&:after
					content ''
					width 0
					height 0
					border-style solid
					border-width 10px 10px 0 10px
					border-color colorPrimary transparent transparent transparent
					position absolute
					bottom -9px
					left 50%
					transform translateX(-50%)
.media-heading
	time
		font-weight 300
		font-size 1rem
		color colorGray
		float right
// Breadcrumbs /////////////////////////////////
.breadcrumb
	background-color transparent
	padding 0
	margin-bottom 60px

// Forms /////////////////////////////////////////////
label
	font-size .9rem
	font-weight 400
	display block
	margin-bottom 5px
	.help_text
		display block
		font-size .9rem
		font-weight 300
		color #aaa
		margin-left 40px
		margin-top -6px
		&.chekbox_label
			margin-top 0
			margin-left 0
	.optional_text
		font-size .9rem
		font-weight 300
		color #aaa

.labels-inline
	label
		display inline-block
	
.form-control
	border-color colorGrayLight
	background-color #f3f6f9
	color colorDark
	box-shadow none
	border-radius 4px
	transition all .3s
	&.bg-white
		background-color white
		border-color white
	&:focus
		background-color white
		border-color colorGray
		box-shadow none
textarea.form-control
	border-radius 4px
	resize vertical
.input-group-addon
	border-color colorGrayLight
div.jfilestyle 
	input
		padding 6px 12px
		height 34px
		background-color #f3f6f9
		border-color #eaeff5
	label
		padding 6px 12px
		height 34px
		color colorDark
		border-color #eaeff5
		font-size .9rem
		line-height 1.42857143
.errorlist
	margin 0
	padding 0
	li
		list-style none
		color colorDanger
		font-size .9rem
.error
	color colorDanger
	font-size .9rem
.form-control.error
	border-color colorDanger !important
input.error-absolute
	border 1px solid colorDanger !important
span.error-absolute
	color colorDanger
	font-size .8rem
	font-weight 300
	position absolute
	top 0px
	left 5px
	z-index 2

// Header Home //////////////////////////////
.wrap_dropdown
	display inline-block

// Categories //////////////////////////
.categories
	display flex
	align-items flex-end
	justify-content center
	margin-bottom 40px
	.category
		text-align center
		margin 15px
		img
			max-width 201px
			margin auto

// Packages ///////////////////////////////////
.wrap_flex_packages
	display flex !important
	max-width 1050px
	margin auto
	.col-md-4
		padding 0px
.box_package
	background-color rgba(colorPrimary, 0.15)
	position relative
	margin 45px 5px 0
	padding 10px 30px 30px
	border-radius 6px
	transition all .6s
	color colorDark

	p
		font-size .8rem
		margin-bottom 18px

	&-title
		font-weight bold
		font-size 28px
		line-height 0.8
		color colorPrimary

	&-price
		display flex
		justify-content center
		color colorDark
		flex-wrap wrap
		span
			line-height 1
		span.currency-symbol
			font-size 18px
			margin-top 7px
			margin-right 2px
		span.price-value
			font-size 50px
			font-weight 400
			&.month-price
				display none
		span.label-type-suscription
			display block
			text-align center
			width 100%

	.list_check
		margin-top 20px
		li
			font-weight 500

			&:before
				font-weight bold

	.icon_package
		position absolute
		top 30px
		right 30px
		color colorPrimary
		i
			font-size 2.2rem

	.btn
		font-weight bold
		position relative
		display flex
		align-items center
		justify-content center
		i
			font-size 1.2em
			margin-left 10px
		&.btn-lg
			height 56px
			line-height 34px
			font-size 18px
			i
				font-size 30px
				margin-left 5px


	.box_icons_package
		display flex
		flex-wrap wrap
		.icon_feature
			text-align center
			width 33.33333333%
			margin-bottom 20px
			span
				font-size 12px
			span, i
				display block
			i
				font-size 1.8rem
				padding 5px
				position relative
				max-width 50px
				margin auto
				.number
					position absolute
					font-size .6rem
					font-family 'Roboto', sans-serif
					top 7px
					left 10px
			&.disabled_icon
				opacity 0.2

	&:hover
		transform scale(1.05)
		z-index 1
	&.without-transform
		transform none
		margin-top 0

	&.featured_package
		background-color colorDefault
		color white
		box-shadow 0 1px 2px rgba(0,0,0,.1)
		.btn-light
			border-color white
			color white

		.box_package-title, .icon_package, .box_package-price
			color white

// Modal
.modal-body-scroll
	max-height 400px
	overflow-y auto


.modal-title
	font-size 22px
	font-weight bold

// Section Newsletter ///////////////////////////
.newsletter
	background-color colorPrimary
	background-image url('../img/bgs/bg_newsletter.jpg')
	background-position center
	background-size cover
	background-repeat no-repeat
	padding 60px 0
	position relative
	.title
		margin-bottom 5px
	.form_newsletter
		position relative
		input
			background-color white
			border 1px solid colorDark
			padding 8px 20px 8px 15px
			border-radius 6px
			box-shadow none
			width 70%
		button.btn
			width 32%
			margin-left -2%
			border-top-left-radius 0
			border-bottom-left-radius 0
	.personal
		width 100%
		input
			margin-right 5px

		.error-absolute
			top 24px
			line-height 1

	.help_text
		margin-bottom 0
		font-size .8rem
		color colorGrayDark
			
.box_call_to_action
	display flex
	align-items center
	padding 20px 30px
	margin-bottom 18px
	border 1px solid colorPrimary
	border-radius 6px
	i
		font-size 3rem
		color colorPrimary
		margin-right 30px
	p
		color colorGray

/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
// Header ////////////////////////////
.header
	background-color colorPrimary
	background linear-gradient(135deg, #0A63BF 0%,#003186 100%)
	color white
	.header_top
		display flex
		align-items center
		padding-top 15px
		padding-bottom 15px
	.header_top_buttons
		text-align right
		.dropdown
			display inline-block
	&.inverted
		position relative
		background rgba(white, 30%)
		height 92px

		.btn-silver
			.icon
				font-size 1.5em
				margin-right 10px
.search
	width 100%
	margin auto
	position relative
	&.search-inverse
		&:before
			border 8px solid rgba(17, 191, 174, 0.1)
	&:before
		content ''
		position absolute
		top -8px
		left -8px
		right -8px
		bottom -8px
		border 8px solid rgba(255, 255, 255, 0.05)
		border-radius 7px
		z-index 0
	.search_main
		border-color white
		height auto
		padding 10px 15px
		font-weight 300
		box-shadow none
		position relative
		z-index 1
	.icon_search
		position absolute
		top 50%
		right 15px
		transform translateY(-50%)
		background-color transparent
		border none
		padding 0
		z-index 2
		color colorPrimary
		font-size 1.3em

// Bar Left //////////////////////////
.list_menu_icon
	margin 0
	padding 0
	a
		li
			list-style none
			padding 15px 40px 0px 40px
			position relative
			span
				color colorDark
				border-bottom 1px solid colorGrayLight
				display block
				padding-bottom 15px
				i
					float right
					font-size 1.4rem
			&.active, &:hover
				background-color rgba(216, 216, 216, .15)
				&:before
					content '\e913'
					font-family 'ubicaya'
					font-size .8rem
					position absolute
					left 20px
					top 50%
					transform translateY(-50%)
		&:last-child
			span
				border-bottom 0
.widget_bar
	margin-bottom 20px
.box-pyp
	background-color #F64D4D
	color white
	padding 15px
	display flex
	align-items center
.pyp-wrapper
	text-align center
	width 100%
.pyp-place
	font-weight 500
	display block
	margin-bottom 5px
.pyp-numbers
	font-size 2.2rem
	font-weight 300
	background-color white
	color colorPrimary
	border-radius 4px
	padding 2px 10px

.actions-place-bottom
	padding 5px 0 30px
	text-align right
	

.box-weather
	background-color #2c93ff
	color white
	padding 15px
	display flex
	align-items center
.weather-wrapper
	width 100%
	.weather-place
		font-weight 500
		display block
		margin-bottom 5px
	.temperature_wrapper
		width 60%
		float left
		.weather-temperature
			font-size 3.5rem
			font-weight 300
			display block
	.icon_wrapper
		display block
		width 40%
		float left
		text-align center
		i
			font-size 6rem
		.weather-description
			text-transform capitalize
			margin-bottom 0
			font-size .8rem

.breadcrumb
	margin-bottom 20px
	.here
		margin-left 15px

/////////
.panel_header
	background-color colorPrimary
	color white
	height 56px
	border-radius 4px
	position relative
	display flex
	align-items center
	justify-content space-between
	.title
		font-size 1.1rem
		font-weight 300
		display inline-block
		margin 0 10px
		width 90%
		a
			color white
	.favorite, .views
		font-size 0.9rem
		float right
		margin 2px 5px 0
		a
			color white
			&:hover
				color colorDanger
	.package
		position absolute
		right 0
		top 0
		height 100%
		font-size 1.3rem
		background-color colorDefault
		width 45px
		border-top-right-radius 4px
		border-bottom-right-radius 4px
		display flex
		align-items center
		justify-content flex-end
		&:before
			margin-right 12px
	.icon-plan
		width 10%
		display flex
		align-items center
		position relative
		background-color colorSilver
		height 100%
		border-bottom-right-radius 3px
		border-top-right-radius 3px

		.icon
			font-size 1.5rem
			margin auto

		&:after
			content ''
			width 0
			height 0
			border-style solid
			border-width 28px 15px 28px 0px
			border-color transparent colorSilver transparent transparent
			position absolute
			right 100%
			top 0
			color colorSilver

.panel_content
	margin 15px 0
	.main_image
		overflow hidden
		width 100%
		max-width 240px
		max-height 232px
		margin auto
		img
			border-radius 4px
			box-shadow 0 1px 2px rgba(0,0,0,.4)
			margin auto

		&.icon_image
			border 1px solid colorGrayLight
			box-shadow none
			min-height 134px
			display flex
			align-items center
			justify-content center
			i
				font-size 3rem
	.calificacion
		margin-top 15px
		display flex
		align-items center
		.label-warning
			background-color #ffd119
			color white
			margin-right 5px
	.stars
		color colorYellow
		margin-right 5px
		text-align center
		i
			margin-right 2px
		
			&:last-child
				font-size 18px

	.sub_category
		margin 0
	.description
		font-size 16px
		line-height 1.4
		color colorGrayDark
		margin 0
.panel_footer
	.address
		display flex
		text-align left
		align-items center
		i
			font-size 28px
			margin-right 5px
			display flex
			align-items center
		p
			margin 0px
			font-size 0.9rem
			font-weight 400
			line-height 1.1

	.web
		margin-top 0px
		i
			margin-right 5px
		a
			color colorDark
			font-weight 500
			font-size 1rem
			word-break break-word
			white-space: pre-wrap;
			word-break: break-all;
			display block

	.social_network
		display inline-block
		margin-right 15px

.panel-contact
	.fa 
		font-size 42px
		margin-right 5px
		
	p
		line-height 1.1
		margin-bottom 0px
		
	a
		color colorDark
		

.panel-search
	.panel_header
		padding 10px 55px 10px 10px
		border-radius 4px
		position relative

		&:after
			content ''
			width 0
			height 0
			border-style solid
			border-width 23px 15px 23px 0px
			border-color transparent colorDefault transparent transparent
			position absolute
			right 45px
			top 0
			color colorDefault

		.title
			font-size 1rem
			font-weight 300
			display inline-block
			margin 0 5px 0 0
			a
				color white
				word-break break-word
				white-space pre-wrap
				word-break break-all
		.views, .favorite
			display flex
			align-items center

			i
				margin-right 3px
	.panel_content
		.stars
			display none

		.description
			font-size 0.9rem
			padding 10px

	.panel_footer
		text-align center
		.address
			margin-bottom 10px
			justify-content center
			p
				font-size 0.9rem

			i
				font-size 22px

	@media (min-width 992px)
		.panel_header
			padding: 10px 65px 10px 15px;
			.title
				font-size: 1.2rem;

		.panel_content
			figure
				width initial
				height initial

		.panel_footer
			.address
				justify-content initial
				margin-bottom 0px

.phone
	a
		display flex
		align-items center
		justify-content flex-end
		color colorDark
		.circle_icon
			width 30px
			height 30px
			border 2px solid colorDark
			border-radius 50%
			display flex
			align-items center
			justify-content center
			&.margin_icon
				margin-right 10px

#map-canvas
	height 300px
	margin-bottom 30px
	.form-control
		right 42px !important
		top 10px !important
		width 300px !important
		
#map-canvas-place, #street-view-place, #directions-place, #map-branchs
	height 350px

.photo-gallery
	position relative
	input[name='_delete_photos']
		position absolute
		top 5px
		left 5px


// Footer ////////////////////////////
.footer
	background-color colorPrimary
	background linear-gradient(135deg, #0A63BF 0%,#003186 100%)
	color white
	font-size .8rem
	.arrow_up
		background-color colorDefault
		height 53px
		margin-bottom 30px
		text-align center
		position relative
		z-index 2
		i
			color white
			font-size 1.6rem
			padding 8px 10px
			border-radius 6px
			background-color colorPrimary
			left 50%
			transform translateX(-50%)
			top -20px
			position absolute

	.subtitle
		color colorGrayLight
	.list_link
		li
			&:before
				content '-'
				padding-right 8px
	a
		color white
		&:hover
			color white
			text-decoration underline
	.menu_footer
		margin 30px 0 0
		li
			display inline-block
			a
				color white
				&:hover
					color white
					text-decoration underline
			.line_d
				height 20px
				background-color white
				margin-top -5px
				margin-bottom -5px

	.footer_bottom
		display flex
		align-items center
		margin-top 45px
		padding 30px 0
		border-top 1px solid white
		&.footer_simple
			text-align center
			padding-top 0
			border none 
			margin 0
		p
			margin-bottom 0
			font-size 0.8rem

.social_network
	text-align right
	margin 0
	line-height 1
	&.social_network--dark
		a
			color colorDark
			opacity 1
			.circle_icon
				border-color colorDark
				.fa
					color colorDark
	li
		display inline-block
		margin 0 2px
		a
			display flex
			align-items center
			color white
			opacity .5
			.circle_icon
				width 38px
				height 38px
				border 2px solid white
				border-radius 50%
				display flex
				align-items center
				justify-content center
				&.margin_icon
					margin-right 10px
				.fa
					color white
					font-size 1.2em
			&:hover
				opacity 1
				text-decoration none
	&.social_network-sm
		text-align left
		margin-left 5px
		li 
			margin 0
			a
				.circle_icon
					width 25px
					height 25px
					.fa
						font-size .8em
				

// Suscription Options
.option_suscription
	background-color white
	padding-left 0 !important
	display flex
	align-items strech
	border 2px solid #F5F7F8
	border-radius 1px
	.action_suscription
		width 50%
		font-size 1.4rem
		line-height 1.4rem
		padding 20px 30px
		display flex
		align-items center
		input
			margin 0 15px 0 0
			position relative
		.text_normal
			margin-top 2px
			margin-left 5px
	.content_suscription
		width 40%
		padding 20px 0px 20px 30px
		display flex
		align-items center
	.price_suscription
		width 50%
		font-size 1.6rem
		font-weight 600
		padding 20px 30px
		display flex
		align-items center
		span
			font-size 1rem
			font-weight 300
			color #aaa
	&:hover, &.active
		border-color colorPrimary
		background-color colorPrimary
		color white
		.price_suscription
			span
				color white
	&.disabled
		&:hover
			cursor default
// .radio
// 	&:nth-of-type(2)
// 		.option_suscription
// 			.action_suscription
// 				background-color #000
// 	&:nth-of-type(3)
// 		.option_suscription
// 			.action_suscription
// 				background-color colorGrayDark
// 				color #000


.canvasjs-chart-credit
	margin-top 10px !important
	display none !important
	
ul.check_list
	padding 0
	text-align left
	li 
		list-style none
		&:before
			content '\f058'
			font-family 'FontAwesome'
			color #98CA3F
			margin-right 10px
					
.loader
	position fixed
	top 0
	left 0
	right 0
	bottom 0
	background-color rgba(255, 255, 255, .97)
	z-index 999999
	display none
	.wrap-loader
		position absolute
		top 0
		left 0
		right 0
		bottom 0
		display flex
		align-items center
		justify-content center
		flex-wrap wrap
		> div
			text-align center
		p
			animation flash 2s linear infinite
			color colorPrimary
			font-weight 600

.royalSlider
	width 100%
	user-select none

.videoAds
	overflow hidden
	position relative
	.wrap-ads
		position absolute
		top 0
		left 0
		right 0
		bottom 0
		background-color #000
		display none
		.close-ads
			position absolute
			z-index 999
			bottom 15px
			right 15px
			cursor pointer
		.embed-responsive
			height 100%
			width 100%


// Blog
.article
	margin-bottom 30px
.article-image
	margin-bottom 30px
	position relative
	text-align center
.article-image-lg
	margin-bottom 30px
.article-date-box
	background-color colorPrimary
	color white
	padding .25rem .5rem
	font-size 1rem
	line-height 1.1
	font-weight bold
	position absolute
	bottom 0
	right 0
.content_post
	img:not(.media-object)
		display block
		max-width 100%
		height auto
.article-tags .chip
	padding 5px 12px
	font-weight normal
	font-size .9rem
	background-color #ECEFF1
	display inline-block
	border-radius 10px
	margin 5px
.author-post
	background-color #f6f6f6
	padding 15px
	border-radius 4px
	margin-bottom 30px
.header-section
	min-height 250px
	display flex
	align-items center
	justify-content center
	background-color colorDefault
	background-size cover
	background-position center
	background-repeat no-repeat
	&.header-blog
		background-image url('../img/headers/header-blog.jpg')
	.container
		text-align center
		color white
		
.pull-right-responsive
	float right !important

.background-image
	background-repeat no-repeat
	background-size cover
	background-position center

	&.small
		height 110px
		width 175px

	&.update
		box-shadow 0 1px 2px rgba(0,0,0,.1)
		border-radius 5px

.update-banner
	.images
		display: flex;
		align-items: center;
		text-align: center;
		flex-flow: row wrap;
		justify-content: center;

	.image
		margin 10px

	input
		margin-right 5px

.left-inner-addon
	position: relative;

	input
		padding-left: 40px;

	i
		position: absolute;
		padding: 10px 12px;
		pointer-events: none;

.right-inner-addon
	position: relative;

	input
		padding-right: 40px

	i
		position: absolute;
		right: 0px;
		padding: 10px 12px;
		pointer-events: none;

#select2-id_category-results, #select2-id_subcategory-results
	.select2-results__option[aria-selected=true]
		display: none;

.avatar-comment
	width 50px
	height 50px
	overflow hidden
	border-radius 50%
	position relative
	border 2px solid colorGrayLight
	img
		position absolute
		transform translateX(-50%)
		left 50%
		width auto
		height 100%

.panel-cities-height-scroll
	position relative
	overflow-y scroll
	max-height 265px


.checkbox label .toggle,
.checkbox-inline .toggle
	margin 0 0 10px 0 !important
	border-radius 24px
	.toggle-handle
		border-radius 24px
	.btn-default
		border-color colorPrimary
		background-color colorPrimary

.place--description-large
	img 
		max-width 100%

.dropdown-menu-icons
	li
		a
			> i 
				width 18px

/////////////////////////////////////////////////////////////
// Mobile CSS ///////////////////////////////////////////////
/////////////////////////////////////////////////////////////
@media (max-width 700px)
	body
		font-size 14px
	.justify-content-sm-center
		justify-content center
	.text-sm-center
		text-align center !important
	.bold-sm
		font-weight 800 !important
	.collapse-sm
		display none
	[data-toggle="collapse-sm"]
		cursor pointer
		position relative
		&.collapsed
			&:after
				content '\f107'
		&:after
			content '\f105'
			font-family "FontAwesome"
			position absolute
			top 50%
			right 12px
			transform translateY(-50%)
			transition content .3s
	.footer
		text-align center
	.box_featured
		img, a
			margin auto
	.wrap_flex_packages
		display block !important
		.box_package .box_icons_package .icon_feature i .number
			left 20%
	.newsletter
		.flex_end
			display block !important
		.form_newsletter
			input
				width 60%
			button.btn
				width 42%
				font-size .9rem
		.box_call_to_action
			margin-top 15px
	.footer
		.menu_footer
			text-align center
			margin 0
			li
				display block
				.line_d
					display none
		.footer_bottom
			display block
			p
				text-align center
			.social_network
				text-align center
				margin-top 15px

	.panel_header
		padding 0px 0px 0px 15px
		&:after
			right 40px !important
		.package
			width 40px
		.favorite, .views
			float none
			text-align center
			margin-top 5px
			margin-left 0
			display inline-block
		.icon-plan
			display none
	.panel_content
		.stars
			margin 5px 0
	.panel_footer
		.address
			text-align center
			margin-bottom 15px
			margin auto
		.flex_center_end
			display block !important
		.phone
			display flex
			margin-top 10px
		.web
			margin auto
	.widget_bar
		img, a
			margin auto
	.bar_left
		.panel-weather, .panel-pyp
			display none
	.bar_right
		.widget_bar
			display none
	.pull-right-responsive
		float none !important
		margin-top 10px
		display block

	// Suscription Options
	.option_suscription
		display block
		.action_suscription
			width 100%
			display block
			padding 10px 20px
		.content_suscription
			width 100%
			display block
			align-items center
		.price_suscription
			width 100%
			font-size 1rem
			padding 10px 20px
			span
				margin-left 3px
	.header_top
		.header_top_buttons
			.btn
				padding 5px 10px
				padding-left 10px
				font-size 12px
				line-height 1.5
				border-radius 3px

	.search .search_main
		height 34px

	.buttons-home-mobile
		margin-bottom 15px
		.btn
			margin-bottom 15px
			padding 5px 10px
			padding-left 10px
			font-size 12px
			line-height 1.5
			border-radius 3px

@media (max-width 960px)
	.container
		padding-left 30px
		padding-right 30px
	.header_top
		display block !important
		.logo
			margin-bottom 15px
			text-align center
		.search
			margin-bottom 15px
		.header_top_buttons
			text-align center
			.wrap_dropdown
				display block
			.dropdown
				button
					margin-right 0 !important
			a
				margin-top 10px
	.buttons-home-mobile
		margin-bottom 15px
		.btn
			margin-bottom 15px


@media (min-width 701px)
	.panel_header
		.title
			font-size 1.4rem
	.panel_content
		.main_image
			max-width 260px

@media (min-width 961px)
	.buttons-home-mobile
		display none


.owl-carousel
	.owl-dots
		position relative
		text-align center
		.owl-dot
			span
				width 47px
				height 10px
				background-color colorGrey
				border none
			&:hover
				span
					background-color rgba(#EA1D5E, 60%)
			&.active
				span
					background-color colorDefault

.btn-default.disabled.focus, .btn-default.disabled:focus, .btn-default.disabled:hover, .btn-default[disabled].focus, .btn-default[disabled]:focus, .btn-default[disabled]:hover, fieldset[disabled] .btn-default.focus, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default:hover
	background-color colorDefault
	border-color colorDefault

.place_list_box
	position relative

	input[type="checkbox"]
		position absolute
		top 2px
		left 6px

.product_images
	display flex
	flex-wrap wrap
	div
		margin-right 10px
		margin-bottom 10px

	.product_img
		position relative

		img
			border 2px solid colorGrayLight
			border-radius 5px

		&:hover
			.overlay_link
				display flex
				opacity 1

		.overlay_link
			opacity 0
			position absolute
			font-size 0.8rem
			font-weight bold
			top 0
			left 0
			width 100%
			height 100%
			background-color rgba(white, 0.9)
			display flex
			align-items center
			justify-content center
			transition opacity 0.2s linear

.product_add_image_spinner
	display flex
	justify-content center
	align-items center
	border 2px solid colorGrayLight
	width 100px
	height 100px

.product_add_image_input
	input
		width 0.1px
		height 0.1px
		opacity 0
		overflow hidden
		position absolute
		z-index -1

		&:focus + label,
		& + label:hover
			background-color white

		&:focus + label
			outline 1px dotted #000
			outline -webkit-focus-ring-color auto 5px

	label
		font-size 0.7rem
		font-weight 700
		color colorGray
		background-color colorGrayLight
		text-align center
		display inline-flex
		justify-content center
		align-items center
		cursor pointer
		width 100px
		height 100px
		border 2px dashed colorGray
		border-radius 5px

		span
			i
				display block
				font-size 1rem
				margin-bottom 0.5rem

		*
			pointer-events none

.carousel-react
	position relative

	.rec-arrow
		position absolute
		background-color rgba(255,255,255,0.1)
		z-index 2

		&:hover:enabled, &:focus:enabled
			background-color rgba(0,0,0,0.9)

	.rec-arrow-right
		right 0

	.rec-dot
		background-color rgba(0,0,0,0.1)
		height 12px
		width 12px
		padding 0
		box-shadow none

		&:hover, &:focus
			box-shadow 0 0 1px 3px rgba(0,0,0,.3)

		&.rec-dot_active
			box-shadow 0 0 0 1px rgba(0,0,0,1);
			background-color rgba(0,0,0,0.9)

	&.carousel-react-banner
		.rec-slider-container
			margin 0
		.rec-arrow
			color #aaaaaa
			font-size 1em
			width 30px
			height 30px
			min-width 30px
			line-height 30px
			opacity 0.3
			&:hover
				opacity 1
		.rec-arrow-left
			left 5px
		.rec-arrow-right
			right 5px

// Shop button floating

.float-help
	position fixed
	right 15px
	top 50%
	margin-top -27px
	height 56px
	width 100px
	padding 5px 10px
	text-align center
	color white
	background-color colorPrimary
	line-height 1.1
	border none
	display flex
	justify-content center
	align-items center
	border-radius 10px
	box-shadow 0px 1px 5px rgba(0,0,0,0.25)
	z-index 9999
	&:hover
		background-color colorPrimaryHover
		color white

@media (max-width 701px)
	.float-help
		top auto
		bottom 20px
		margin-top 0