/******************************
*
*  RoyalSlider Universal Skin
*
*    1. Arrows 
*    2. Bullets
*    3. Thumbnails
*    4. Tabs
*    5. Fullscreen button
*    6. Play/close video button
*    7. Preloader
*    8. Caption
*    
*  Sprite: 'rs-universal.png'
*  Feel free to edit anything
*  If you don't some part - just delete it
* 
******************************/


/* Background */
.rsUni,
.rsUni .rsOverflow,
.rsUni .rsSlide,
.rsUni .rsVideoFrameHolder,
.rsUni .rsThumbs {
	background: #fff;
	color: #4A4A4A;
}


/***************
*
*  1. Arrows
*
****************/

.rsUni .rsArrow {
	height: 100%;
	width: 60px;
	position: absolute;
	display: block;
	cursor: pointer;
	z-index: 21;
}
.rsUni.rsVer .rsArrow {
	width: 100%;
	height: 60px;
	
}
.rsUni.rsVer .rsArrowLeft { top: 0; left: 0; }
.rsUni.rsVer .rsArrowRight { bottom: 0;  left: 0; }

.rsUni.rsHor .rsArrowLeft { left: 0; top: 0; }
.rsUni.rsHor .rsArrowRight { right: 0; top:0; }

.rsUni .rsArrowIcn {		
	width: 32px;
	height: 32px;
	top: 50%;
	left: 50%;
	margin-top:-16px;	
	margin-left: -16px;

	position: absolute;	
	cursor: pointer;	
	background: url('rs-universal.png');
	opacity: 0.5;
	border-radius: 2px;
}
.rsUni .rsArrow:hover .rsArrowIcn {
	opacity: 1;
}

.rsUni.rsHor .rsArrowLeft .rsArrowIcn { background-position: -64px -32px; }
.rsUni.rsHor .rsArrowRight .rsArrowIcn { background-position: -64px -64px; }

.rsUni.rsVer .rsArrowLeft .rsArrowIcn { background-position: -96px -32px; }
.rsUni.rsVer .rsArrowRight .rsArrowIcn { background-position: -96px -64px; }

.rsUni .rsArrowDisabled .rsArrowIcn { opacity: .1 !important; filter: alpha(opacity=20);  *display: none; }


/***************
*
*  2. Bullets
*
****************/

.rsUni .rsBullets {
	position: relative;
	z-index: 35;
	padding-top: 4px;
	width: 100%;
	height: auto;
	margin: 0 auto; 

	text-align: center;
	line-height: 5px;
	overflow: hidden;
}
.rsUni .rsBullet {
	width: 8px;
	height: 8px;
	display: inline-block;
	*display:inline; 
	*zoom:1;
	padding: 5px;
}
.rsUni .rsBullet span {
	display: block;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #BBB;
}
.rsUni .rsBullet.rsNavSelected span {
	background-color: #383838;
}





/***************
*
*  3. Thumbnails
*
****************/

.rsUni .rsThumbsHor {
	width: 100%;
	height: 72px;
}
.rsUni .rsThumbsVer {
	width: 96px;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
}
.rsUni.rsWithThumbsHor .rsThumbsContainer {
	position: relative;
	height: 100%;
}
.rsUni.rsWithThumbsVer .rsThumbsContainer {
	position: relative;
	width: 100%;
}
.rsUni .rsThumb {
	float: left;
	overflow: hidden;
	width: 96px;
	height: 72px;
}
.rsUni .rsThumb img {
	width: 100%;
	height: 100%;
}
.rsUni .rsThumb.rsNavSelected {
	background: #333;
}
.rsUni .rsThumb.rsNavSelected img {
	opacity: 0.7;
	filter: alpha(opacity=30);
}
.rsUni .rsThumb.rsNavSelected .thumbIco {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border: 2px solid #11BFAE;
	border: 2px solid rgba(17, 191, 174,0.9);
	-webkit-backface-visibility: hidden;
} 

.rsUni .rsTmb {
	display: block;
}

/* Thumbnails with text */
.rsUni .rsTmb h5 {
	font-size: 16px;
	margin: 0;
	padding: 0;
	line-height: 20px;
	color: #FFF;
}
.rsUni .rsTmb span {
	color: #DDD;
	margin: 0;
	padding: 0;
	font-size: 13px;
	line-height: 18px;
}



/* Thumbnails arrow icons */
.rsUni .rsThumbsArrow {
	height: 100%;
	width: 20px;
	position: absolute;
	display: block;
	cursor: pointer;	
	z-index: 21;	
}
.rsUni .rsThumbsArrow:hover {

}
.rsUni.rsWithThumbsVer .rsThumbsArrow {
	width: 100%;
	height: 20px;
}
.rsUni.rsWithThumbsVer .rsThumbsArrowLeft { top: 0; left: 0; }
.rsUni.rsWithThumbsVer .rsThumbsArrowRight { bottom: 0;  left: 0; }

.rsUni.rsWithThumbsHor .rsThumbsArrowLeft { left: 0; top: 0; }
.rsUni.rsWithThumbsHor .rsThumbsArrowRight { right: 0; top:0; }

.rsUni .rsThumbsArrowIcn {		
	width: 16px;
	height: 16px;
	top: 50%;
	left: 50%;
	margin-top:-8px;	
	margin-left: -8px;
	position: absolute;	
	cursor: pointer;	
	background: url('rs-universal.png') transparent;
}

.rsUni.rsWithThumbsHor .rsThumbsArrowLeft .rsThumbsArrowIcn { background-position: -128px -32px; }
.rsUni.rsWithThumbsHor .rsThumbsArrowRight .rsThumbsArrowIcn { background-position: -128px -48px; }

.rsUni.rsWithThumbsVer .rsThumbsArrowLeft .rsThumbsArrowIcn { background-position: -144px -32px; }
.rsUni.rsWithThumbsVer .rsThumbsArrowRight .rsThumbsArrowIcn { background-position: -144px -48px; }

.rsUni .rsThumbsArrowDisabled { display: none !important; }

/* Thumbnails resizing on smaller screens */
@media screen and (min-width: 0px) and (max-width: 800px) {
	.rsUni .rsThumb {
		width: 59px;
		height: 44px;
	}
	.rsUni .rsThumbsHor {
		height: 44px;
	}
	.rsUni .rsThumbsVer {
		width: 59px;
	}
}




/***************
*
*  4. Tabs
*
****************/

.rsUni .rsTabs {
	width: 100%;
	height: auto;
	margin: 0 auto;
	text-align:center;
	overflow: hidden; padding-top: 12px; position: relative;
}
.rsUni .rsTab {
	display: inline-block;
	cursor: pointer;
	text-align: center;
	height: auto;
	width: auto;
	color: #333;
	padding: 5px 13px 6px;
	min-width: 72px;
	border: 1px solid #D9D9DD;
	text-decoration: none;

	background: #FFF;
	border-right: none;
	*display:inline; 
	*zoom:1;
	*border-right: 1px solid #d9d9d9;
	
}
.rsUni .rsTab:first-child {
	border-top-left-radius: 16px;
	border-bottom-left-radius: 16px;
}
.rsUni .rsTab:last-child { 
	border-top-right-radius: 16px;
	border-bottom-right-radius: 16px;
	border-right: 1px solid #D9D9DD;
}
.rsUni .rsTab:active { 
	background-color: #f4f4f4;
}
.rsUni .rsTab.rsNavSelected { 
	color: #FFF;
	background: #383838;
	border-color: #383838;
}





/***************
*
*  5. Fullscreen button
*
****************/

.rsUni .rsFullscreenBtn {
	right: 0;
	top: 0;
	width: 44px;
	height: 44px;
	z-index: 22;
	display: block;
	position: absolute;
	cursor: pointer;
	
}
.rsUni .rsFullscreenIcn {
	display: block;
	margin: 6px;
	width: 32px;
	height: 32px;

	background: url('rs-universal.png') 0 0 transparent;
	opacity: 0.7;
	border-radius: 2px;

}
.rsUni .rsFullscreenBtn:hover .rsFullscreenIcn {
	opacity: 1;
}
.rsUni.rsFullscreen .rsFullscreenIcn {
	background-position: -32px 0;
}





/***************
*
*  6. Play/close video button
*
****************/

.rsUni .rsPlayBtn {
	-webkit-tap-highlight-color:rgba(0,0,0,0.3);
	width:64px;
	height:64px;
	margin-left:-32px;
	margin-top:-32px;
	cursor: pointer;
}
.rsUni .rsPlayBtnIcon {
	width:64px;
	display:block;
	height:64px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	
	-webkit-transition: .3s;
	-moz-transition: .3s;
	transition: .3s;

	background:url(rs-universal.png) no-repeat 0 -32px;
	background-color: #383838;
	background-color: rgba(0,0,0,0.75);
	*background-color: #383838;
}
.rsUni .rsPlayBtn:hover .rsPlayBtnIcon {
	background-color: rgba(0,0,0,0.9);
}
.rsUni .rsBtnCenterer {
	position:absolute;
	left:50%;
	top:50%;
	width: 20px;
	height: 20px;
}
.rsUni .rsCloseVideoBtn {
	right: 0;
	top: 0;
	width: 44px;
	height: 44px;
	z-index: 500;
	position: absolute;
	cursor: pointer;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateZ(0);
	
}
.rsUni .rsCloseVideoBtn.rsiOSBtn {
	top: -38px;
	right: -6px;
}

.rsUni .rsCloseVideoIcn {
	margin: 6px;
	width: 32px;
	height: 32px;
	background: url('rs-universal.png') -64px 0;
	background-color: #383838;
	background-color: rgba(0,0,0,0.75);
	*background-color: #383838;
}
.rsUni .rsCloseVideoIcn:hover {
	background-color: rgba(0,0,0,0.9);
}



/***************
*
*  7. Preloader
*
****************/

.rsUni .rsPreloader {
	width:20px;
	height:20px;
	background-image:url(preloaders/preloader-white.gif);

	left:50%;
	top:50%;
	margin-left:-10px;
	margin-top:-10px;	
}




/***************
*
*  8. Global caption
*
****************/
.rsUni .rsGCaption {
	position: absolute;
	float: none;
	bottom: 6px;
	left: 6px;
	text-align: left;

	background: #222;

	color: #FFF;
	padding: 2px 8px;
	width: auto;
	font-size: 12px;
	border-radius: 2px;
}