.header--theme1
	position absolute
	width 100%
	left 0
	z-index 10
	&.header-black
		background-color rgba(#111, .5)
		&.sticky
			background-color rgba(#111, .9)
	&.header-white
		background-color rgba(white, .5)
		&.sticky
			background-color rgba(white, .9)
	.row
		display flex
		flex-wrap wrap
		align-items center
		padding-top .75rem
		padding-bottom .75rem
	.logo
		img
			max-height 75px
			transition all .2s
	&.sticky
		position fixed
		top 0
		width 100%
		z-index 1040
		.logo img
			max-height 50px

.Anuncio.theme1
	padding 0
	.Banner
		margin-top 0
		color white
		height 80vh
		min-height 480px
		background-color colorDark
	.main-banner-carousel
		position absolute
		top 0
		left 0
		z-index 0
		img
			object-fit cover
			height 80vh
			min-height 480px

	.banner-text
		padding-top 92px
		height 100%
		.row
			display flex
			flex-wrap wrap
			align-items flex-end
			padding-bottom 2rem
			height 100%
	.panel-galery
		.galery
			width auto
			height 180px
			border-radius 0
			border 1px solid colorDark
			.description 
				.icon-camera
					font-size 22px
				p
					margin-top .5rem
					font-size .9rem
	.place--description-large
		> br
			display none

	.btn-call-us
		display inline-flex
		align-items center
		flex-wrap wrap
		border 1px solid white
		i
			font-size 1.4em
			margin-right .5rem

.pre-header.theme1
	background-color #333
	color white
	height 40px
	font-size .8rem
	.row
		height 40px
		display flex
		flex-wrap wrap
		align-items center
	p
		margin-bottom 0

.pre-header--right
	display inline-flex
	align-items center
	justify-content flex-end
	.header--info
		display inline-flex
		align-items center
		span
			display inline-flex
			align-items center
		.phone, .email
			margin-right 1.25rem
			img 
				margin-right 0.5rem
		
.theme1--menu
	display flex
	justify-content flex-end
	ul
		li
			a
				color white
				text-transform uppercase
				font-family 'Montserrat', sans-serif
				font-weight 600
				font-size .95rem
				&:hover, &:focus
					background-color transparent
					opacity 0.5

.header--social-networks
	border-left 1px solid #D8D8D8
	border-right 1px solid #D8D8D8
	padding 0 10px
	height 40px
	display flex
	align-items center
	ul.social_network
		margin-left 0
		li
			a
				color white
				font-size 1.1rem
				margin 0 5px
				opacity 1
				.circle_icon
					border-width 1px

.form-header-theme1
	margin-bottom -9rem
	padding .5rem
	.panel-heading
		background-color white !important
		color colorDark !important
		.h-title
			font-size 1.2rem
			font-family 'Montserrat', sans-serif
			font-weight 600
			margin-bottom 0
			text-transform uppercase
		p.lead
			margin-bottom 0
	.panel-body
		padding-top 0 !important
	
	.form_contact_place.theme1
		.form-group:first-child, 
		.form-group:nth-child(2)
			width 100%
		.form-group input
			height 44px
			line-height 44px
	.form-control
		background-color #EEEDED
		color #979797
		&:focus
			background-color #fafafa

.sub-header-theme1
	padding 1.2rem 0
	background-color #F7F7F7
	.row
		display flex
		flex-wrap wrap
		align-items center
		h1
			font-size 2rem
		.sub-header-right
			text-align right
			.btn-whatsapp
				border-color white
	.calificacion
		margin-bottom 1rem
	p
		margin-bottom 0

.address-info
	display flex
	text-align left
	align-items center
	i
		font-size 28px
		margin-right 5px
		display flex
		align-items center
	p
		margin 0px
		font-size 0.9rem
		font-weight 400
		line-height 1.1
		
.panel-video
	border-radius 6px
	overflow hidden

.bar-dark-theme1
	background-color colorDark
	color white
	padding 1.5rem 0
	.row
		display flex
		align-items center
	p
		margin-bottom 0

.nav-tabs.nav-tabs-theme1
	> li
		float none
		margin-bottom .5rem
		> a
			text-align left
		&.active a::after
			border-width 10px 0 10px 10px
			border-color transparent transparent transparent #0054ae
			position absolute
			right -9px
			left auto
			top 50%
			transform translateY(-50%)

.item--gallery
	margin-top 1rem
	.gallery-box
		height 200px
		background-color #f2f2f2
		background-size cover
		background-repeat no-repeat
		margin-bottom 2rem
		position relative
		overflow hidden
		&:before, &:after
			position absolute
			top 0
			right 0
			left 0
			bottom 0
		&:after
			content ''
			background-color inherit
			opacity 0.75
			transition background-color 200ms ease-in-out
			transform translate3d(0,-100%,0)
			transition all 100ms ease
		&:hover
			&:after
				transform translate3d(0,0,0)
			.description
				transform translate3d(0,0,0)
				opacity 1 !important
				top 5%
				bottom 10%

		.description
			position absolute
			top 0px
			left 0px
			right 0px
			color white
			font-size 16px
			z-index 8
			opacity 0
			padding 20px
			text-align center
			transition background-color 200ms ease-in-out
			transform translate3d(0,-100%,0)
			transition all 100ms ease
			display flex
			flex-direction column
			justify-content center

			.icon-camera
				font-size 40px
				color white

			p
				font-weight 500
				margin-bottom 0px

#accordionServices
	.panel-title
		a
			display block
			&[data-toggle="collapse"]
				i:before
					content "\f196"
			&[aria-expanded="true"]
				i:before
					content "\f147"

.testimonials-section
	position relative
	background-color #FAFAFA
	padding-bottom 6rem !important
	background-image url('../img/rectangle.png')
	background-position center bottom
	background-size contain
	background-repeat no-repeat
	&:before, &:after
		background none
	.card
		width 100%
		min-height 240px
		padding 50px 30px 30px 50px
	.testimonials--title
		text-align center

	.testimonios
		.testimonial-title
			width 82%
		.testimonial-link
			background-color #333
	.owl-carousel .owl-dots 
		.owl-dot
			&:hover span
				background-color #666
			&.active span
				background-color #333

.clients-section
	padding-top 5rem
	background none
	.owl-default .owl-next, 
	.owl-default .owl-prev
		color #333333

.section-blog-theme1
	padding-top 2rem
	padding-bottom 1rem
		
.contact-section
	background-color #FAFAFA
	padding-top 11rem
	padding-bottom 4rem
	background-image url('../img/angle.png')
	background-position center top
	background-size contain
	background-repeat no-repeat

	.icon-contact
		font-size 3rem

	.contact-form-theme1
		.form-control
			background-color #EEEDED
			color #979797
			&:focus
				background-color white

.content-map.theme1
	background-color #fafafa
	.nav-tabs-map
		margin-top 0
		padding-top 15px

.footer.theme1
	background-color #333333
	background linear-gradient(135deg, #333333 0%,#444444 100%)
	.arrow_up
		background-color #282828
		i
			background-color #333333
	.row
		display flex
		flex-wrap wrap
		align-items center
	.logo-footer
		opacity .18
		.padding-logo-footer
			padding-top 1.8rem
	.btn
		&:hover, &[aria-expanded="true"]
			text-decoration none
			background-color #282828 !important

// Shop button floating

.float-shop-btn
	position fixed
	left 15px
	bottom 20px
	top auto
	color white
	background-color #333
	border none
	width 56px
	height 56px
	display flex
	justify-content center
	align-items center
	border-radius 50%
	box-shadow 0px 1px 5px rgba(0,0,0,0.25)
	z-index 9999999999
	i.fa
		font-size 2em
	&:hover
		color white

// Whatsapp

.float-whatsapp
	position fixed
	right 15px
	bottom 20px
	top auto
	color white
	background-color colorWhatsapp
	border none
	width 56px
	height 56px
	display flex
	justify-content center
	align-items center
	border-radius 50%
	box-shadow 0px 1px 5px rgba(0,0,0,0.25)
	z-index 9999999999
	i.fa
		font-size 2.4em
	&:hover
		color white

// Mobile nav

.navbar-toggler
	position fixed !important
	left 15px
	bottom 20px !important
	top auto !important
	z-index 9999999999 !important
	color rgba(255,255,255,0.85) !important
	border none
	width 56px
	height 56px
	border-radius 50%
	background-color #fff
	box-shadow 0px 1px 5px rgba(0,0,0,0.25)
	display none

	.navbar-toggler-line
		width 24px
		height 2px
		background-color colorDark
		border-radius 1px
		display block
		margin 4px auto
		transition all 0.3s

.navbar-toggler.expanded .navbar-toggler-line
	transform-origin 12px
	margin auto

.navbar-toggler.expanded .navbar-toggler-line:first-child
	transform rotate(45deg)

.navbar-toggler.expanded .navbar-toggler-line:nth-child(2)
	display none

.navbar-toggler.expanded .navbar-toggler-line:last-child
	margin-top -2px
	transform rotate(-45deg)

.mobile-nav
	display none
	position fixed
	top 0
	left 0
	width 100%
	bottom 0
	overflow-x auto
	z-index 99999999
	visibility hidden
	transition visibility 0.2s linear

	.overlay-mobile-nav
		position fixed
		top 0
		left 0
		width 100%
		bottom 0
		background-color rgba(0,0,0,0.2)

	.navbar-nav
		background-color colorDark
		position fixed
		top 0
		left -110%
		bottom 0
		width 70%
		margin 0
		overflow-y scroll
		transition left 0.3s linear
		li
			display block
			border-bottom 1px solid rgba(255,255,255,0.1)
			text-align left
			float none
			text-transform uppercase
			font-size 16px
			a
				color #fff
				padding 1rem
				&:hover,
				&:active,
				&:focus
					background-color rgba(0,0,0,0.2)

	.title-section-menu h5
		text-transform uppercase
		color rgba(255,255,255,0.4)
		font-size 0.8rem
		padding 15px
		margin 0
		border-bottom 1px solid rgba(255,255,255,0.1)

	&.active-menu
		visibility visible

	&.active-menu .navbar-nav
		left 0

.d-none
	display none !important

#menu-shop
	position relative
	display inline-block
	padding-right 44px
	span
		position absolute
		display inline-block
		top 50%
		right 0
		transform translateY(-50%)
		padding 5px 8px 5px 7px
		i
			font-size 1.2rem

.container-datafono
	position relative
	max-width 900px
	margin 0 auto 40px auto

	img
		border-radius 12px

	.btn
		position absolute
		top 50%
		right 50px
		transform translateY(-50%)

// Large devices Desktops (≥1200px)
// @media (min-width 1600px)
@media (min-width 1200px)
	.Anuncio.theme1
		.panel-galery
			.galery
				height 220px


// Medium devices Desktops (≥992px)
@media (max-width 1199px)
	.d-md-none
		display none !important
	.d-md-block
		display block !important

	.pre-header.theme1
		.row
			.pre-header--right
				width 100%
				justify-content space-between
		.pre-header--left
			display none

	.contact-section
		padding-top 8rem

// @media (min-width 992px)


// Small devices Tablets (≥768px)
@media (max-width 991px)
	.header--theme1
		.container .row
			justify-content center

	.testimonials-section
		padding-bottom 4rem !important
	.clients-section
		padding-top 4rem
	.contact-section
		padding-top 7rem
	.theme1--menu
		display none
	.navbar-toggler,
	.mobile-nav
		display block

	.d-sm-none
		display none !important
	.d-sm-block
		display block !important

	.sub-header-theme1
		text-align center
		.row
			justify-content center
			.sub-header-right
				text-align center
				margin-top 2rem
				.btn
					margin-bottom 1rem

	.container-datafono
		.btn
			right 20px

@media (min-width 768px)
	.Anuncio.theme1
		.Banner
			height 60vh
			min-height 400px
		.main-banner-carousel img
			height 60vh
			min-height 400px


// Extra small devices Phones (<768px)
@media (max-width 767px)
	.d-xs-none
		display none !important
	.d-xs-block
		display block !important
	.section
		padding 50px 0 !important
	.header--theme1
		.logo 
			img
				max-height 60px
	.pre-header.theme1
		height auto
		.row
			display block
			height auto
			.pre-header--right
				display block
				justify-content center !important
				.header--info
					display none
					// padding-top .5rem
					// text-align center
		.header--social-networks
			// display none
			justify-content center
			border none
			height auto
			margin-top .6rem
			margin-bottom .3rem
			ul.social_network li a
				margin 0 1px 4px 1px

	.header--theme1
		.row
			justify-content center
			.theme1--menu
				text-align center
				display none
	.Anuncio.theme1 
		.Banner
			height auto
			min-height 32vh
			padding-top 0rem
			padding-bottom 0rem
			display flex
			align-items flex-end
		.main-banner-carousel img
			height auto
			min-height 32vh
		.banner-text
			width 100%
			padding-top 0
			.row
				padding-bottom 0
			h1
				font-size 1.6rem
			.lead
				font-size 1rem
			.btn
				margin-right 0 !important
				margin-top 1rem
	.sub-header-theme1
		.row
			h1
				font-size 1.6rem
			.lead
				font-size 1rem
	.item--gallery
		.gallery-box
			height 200px

	.item-features
		margin-bottom 2rem
	.panel-video
		margin-bottom 1.5rem
	#s-servicios
		margin-top 1rem !important
	.bar-dark-theme1 .row
		flex-wrap wrap
		.col-lg-5
			margin-bottom 1rem
	.testimonials-section
		padding 3rem 0 !important
		.card
			min-height 270px
			padding 60px 15px 15px 15px
			p:nth-child(2), 
			p:nth-child(3)
				text-align justify
		.card-image
			left 50%
			margin-left -51px
		.testimonios
			padding-bottom 2rem
			.testimonial-title
				font-size 1rem
				left 15px
	.contact-section
		padding-top 5rem
		padding-bottom 3rem
		.media
			margin-bottom 1rem
	
	.footer.theme1
		.logo-footer
			padding-top 0
			margin-bottom 1rem
		.col-md-6
			width 100%
			&.text-right
				text-align center
			.btn
				margin 1rem 0 0 0 !important
			.wrap_dropdown
				display block
		.footer_bottom
			justify-content center

@media (max-width 570px)
	.container-datafono
		.btn
			top 30%