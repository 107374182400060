
@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeue-Bold.eot');
    src: url('../fonts/HelveticaNeue-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HelveticaNeue-Bold.woff2') format('woff2'),
        url('../fonts/HelveticaNeue-Bold.woff') format('woff'),
        url('../fonts/HelveticaNeue-Bold.ttf') format('truetype'),
        url('../fonts/HelveticaNeue-Bold.svg#HelveticaNeue-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeue-Medium.eot');
    src: url('../fonts/HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HelveticaNeue-Medium.woff2') format('woff2'),
        url('../fonts/HelveticaNeue-Medium.woff') format('woff'),
        url('../fonts/HelveticaNeue-Medium.ttf') format('truetype'),
        url('../fonts/HelveticaNeue-Medium.svg#HelveticaNeue-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeue-Light.eot');
    src: url('../fonts/HelveticaNeue-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HelveticaNeue-Light.woff2') format('woff2'),
        url('../fonts/HelveticaNeue-Light.woff') format('woff'),
        url('../fonts/HelveticaNeue-Light.ttf') format('truetype'),
        url('../fonts/HelveticaNeue-Light.svg#HelveticaNeue-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeue-UltraLight.eot');
    src: url('../fonts/HelveticaNeue-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HelveticaNeue-UltraLight.woff2') format('woff2'),
        url('../fonts/HelveticaNeue-UltraLight.woff') format('woff'),
        url('../fonts/HelveticaNeue-UltraLight.ttf') format('truetype'),
        url('../fonts/HelveticaNeue-UltraLight.svg#HelveticaNeue-UltraLight') format('svg');
    font-weight: 100;
    font-style: normal;
}
