.Anuncio
	padding-bottom 35px

	.Banner
		background-size cover
		background-position 50% 8%
		background-repeat no-repeat
		width 100%
		height 60vh

	.Content
		.row
			.main-panel:not(.bar_right)
				margin-top -180px

			.main-panel
				.panel_content
					.main_image
						max-width 240px
						.image
							background-position center
							background-repeat no-repeat
							width 240px
							height 180px
							background-size cover
							border-radius 4px
							box-shadow 0 1px 2px rgba(0,0,0,0.4)
							margin auto

				.panel_footer
					.btn
						color white

					.address-content
						&:hover
							.btn-hover
								opacity 1
								visibility visible

					.btn-hover
						transition ease 0.5s all
						opacity 0
						visibility hidden
						position relative
						background-color #ECECEC
						border-color #ECECEC
						color colorDark
						font-weight 400
						box-shadow 0 1px 2px colorGray
						padding 9px 12px
						margin-left 15px
						display flex
						align-items center

						&:before
							content ""
							display block
							width 0
							height 0
							border-top 12px solid transparent
							border-bottom 12px solid transparent
							border-right 12px solid #ECECEC
							position absolute
							top 24%
							left -12px

			.panel-button
				box-shadow none !important 
				border none !important
				.btn
					&.call
						padding 8px 16px !important
						display flex
						align-items center
						justify-content center
						i
							font-size 30px
							margin-right 15px

				.btn-custom
					cursor default
					position relative
					padding-left 40px
					background-color colorPrimary !important
					border-color colorPrimary !important

				.icon-circle-check
					position absolute
					top 6px
					left 13%
					font-size 30px

				&.responsive
					background-color transparent
					width 90%
					margin 0px auto -2px

					.btn
						max-width 360px
						margin 20px auto 0px
						text-overflow ellipsis
						overflow hidden
						white-space nowrap

					.btn-custom
						font-size 16px

						i
							left 4%

			.panel-contact
				display flex
				align-items center
				justify-content space-between
				flex-flow row wrap
				.btn-panel
					background linear-gradient(to bottom, #0054AE, #003186)
					background-repeat repeat-x
					box-shadow inset 0 1px 0 rgba(255,255,255,0.2), 0 1px 2px rgba(0,0,0,0.05)
					display inline-block
					align-self stretch
					border 1px solid #003186
					border-radius 4px
					margin-bottom 6px
					&:first-child:nth-last-child(1)
						width 100%
					&:first-child:nth-last-child(2),
					&:first-child:nth-last-child(2) ~ .btn-panel
						width 49.5%
					&:first-child:nth-last-child(3),
					&:first-child:nth-last-child(3) ~ .btn-panel
						width 33%
					&:last-child
						border-right 0
					a
						display flex
						align-items center
						justify-content center
						height 100%
						padding 10px 30px
						color white
					&:hover
						background linear-gradient(to bottom, #003186, #003186)
						transition background 0.1s linear
						border-color #003186
			
					&.place-email
					.link
						color white

					.btn
						color white
						font-weight bold

				.icomoon
					font-size 36px
					margin-right 10px

				p
					font-weight 400
					
				p:last-child
					font-weight 300
					a
						word-break break-word

		.panel-logo
			margin auto

	.panel-icon
		padding 8px 20px !important
		display flex
		align-items center
		justify-content center
		font-size 18px
		font-weight 300
		border-radius 6px

		i
			font-size 34px
			margin-right 10px

		i.icon-advertising
			font-size 26px

	.panel-galery
		.owl-item
			img
				border-radius 7px
				
		.galery
			background-size cover
			background-position 100% 8%
			background-repeat no-repeat
			height 110px
			width 160px
			position relative
			overflow hidden
			margin auto
			border-radius 5px
			.link
				display block
				width 100%
				height 100%

			&:before, &:after
				position absolute
				top 0
				right 0
				left 0
				bottom 0

			&:after
				content ''
				background-color inherit
				opacity 0.75
				transition background-color 200ms ease-in-out
				transform translate3d(0,-100%,0)
				transition all 100ms ease
			&:hover
				&:after
					transform translate3d(0,0,0)
				.description
					transform translate3d(0,0,0)
					opacity 1 !important
					top 5%
					bottom 10%

			.description
				position absolute
				top 0px
				left 0px
				right 0px
				color white
				font-size 16px
				z-index 8
				opacity 0
				padding 20px
				text-align center
				transition background-color 200ms ease-in-out
				transform translate3d(0,-100%,0)
				transition all 100ms ease
				display flex
				flex-direction column
				justify-content center

				.icon-camera
					font-size 40px
					color white

				p
					font-weight 500
					margin-bottom 0px

	.panel-download
		.panel-body
			padding 10px 25px 30px
		img
			width 100%
			display block
			margin-bottom 10px
			border-radius 10px

	.panel-schedule
		
		.list_line
			padding 0 15px
			list-style none
			li
				padding 6px 0px
				border-bottom 1px solid colorGrayLight
				.item
					font-weight 300
				&:last-child
					border-bottom 0

			.item
				display flex
				align-items center
				justify-content space-around

				span:first-child
					width 45%
				span:last-child
					width 55%
					text-align right

	.panel-blue
		padding 20px

		.input-group
			margin-bottom 30px

			input
				background-color transparent
				border-style dashed
				height 42px
				border-color white
				color white
				font-size 16px

			button
				height 42px
				padding 6px 20px
				font-weight 400
				font-size 16px

				&.btn-copy
					&:hover
						font-weight bold

		.social_network
			display flex
			align-items center

			li
				margin 0 5px
		.social_icons
			display flex
			align-items center
			justify-content center
			padding 5px 0 15px
			.circle_icon
				border-color white
				width 38px
				height 38px

			.fa
				color white
				font-size 22px
				
			.fa-whatsapp
				font-size 44px

	.panel-characteristics
		.panel-body
			padding 0px 25px 5px
		.list_line
			margin 0
			padding 0
			li
				display flex
				align-items center
				justify-content space-between
				list-style none
				padding 10px 15px 10px 15px
				position relative
				border-bottom 1px solid colorGrayLight
				.item
					font-weight 300
				.icomoon
					font-size 28px
				&:last-child
					border-bottom 0

	.panel-comment
		.checkbox
			margin 0px

	@media (min-width 340px)
		.Content
			.row
				.main-panel
					.panel_content
						.main_image
							max-width 260px
							.image
								width 260px

	@media (min-width 400px)
		.Content
			.row
				.panel-button
					&.responsive
						.btn-custom
							font-size 18px
							
							i
								left 13%
								
				.panel-schedule
					ul
						padding-left 20px
	
	@media (min-width 794px)
		.Content
			.row
				.main-panel
					margin-top -120px !important

					.panel_content
						.col-md-5
							padding-right 0px

						.col-md-7
							padding-top 5px
							padding-right 25px
							padding-left 10px

	@media (min-width 768px)

		.Banner
			height 65vh
			min-height 450px

		.Content
			.row
				.main-panel
					.btn.open
						margin-bottom 10px

					.btn.call
						font-size 20px
						font-weight 500

						i
							margin-right 15px

				.panel-button
					border none
					background-color transparent
					.btn-custom
						margin-bottom 20px

	@media (min-width 970px)

		.Content
			.row
				.panel-contact
					.btn-panel
						&.place-email
							.btn
								color colorDark
								background-color white
								border-color colorDark
	
	@media (max-width 540px)
		.Content
			.row
				.panel-contact 
					.btn-panel
						&:first-child:nth-last-child(1),
						&:first-child:nth-last-child(2),
						&:first-child:nth-last-child(2) ~ .btn-panel,
						&:first-child:nth-last-child(3),
						&:first-child:nth-last-child(3) ~ .btn-panel
							width 100%
						
.tab-pane#mapa
	.container-fluid
		.col-md-6
			padding 0
			&:first-child
				border-right 3px solid colorDark
			&:last-child
				position relative
				overflow hidden
				height 350px
				border-left 3px solid colorDark
				img
					position absolute
					left 50%
					top 50%
					transform translate(-50%, -50%)
					width auto
					height 120%

.content-map
	.nav-tabs-map
		margin 15px auto
		width 86%
		max-width 1170px

@media (max-width 460px)
	.Anuncio
		padding-bottom 15px
		.panel_content .description
			text-align justify
			margin-top 10px
		.note-editable
			text-align justify
	
		.Content .row .panel-button.responsive
			margin 0px auto 10px
		.panel-galery .galery
			width auto
			height 90px
	.content-map
		.nav-tabs-map
			width 100%
			padding-left 15px
			padding-right 15px
			li
				width 100%
				margin-bottom 11px
				a
					margin-right 0
	.social_icons
		flex-wrap wrap
		small
			width 100%
			margin-bottom 5px
		ul
			width 100%
	
	.tab-pane#mapa .container-fluid .col-md-6
		&:first-child
			border 0
		&:last-child
			height 200px
			border-left 0
			border-top 3px solid colorDark
			img
				min-width 120%
				height auto

@media (max-width 700px)
	.Anuncio .Content .row
		.main-panel:not(.bar_right)
			margin-top -100px
				
@media (max-width 990px)
	.social_icons
		margin-bottom 10px
	
	.tab-pane#mapa .container-fluid .col-md-6
		&:first-child
			border 0
		&:last-child
			height 250px
			border-left 0
			border-top 3px solid colorDark
			img
				min-width 120%
				height auto
	.Anuncio .Content .row .panel-button.responsive .btn
		margin-bottom 20px
#mainSlider
	border-radius 10px

.form_contact_place
	display flex
	flex-flow row wrap
	justify-content space-between

	.form-group
		width 100%

		input
			box-shadow 1px 2px 2px colorGrayLight
			border-color transparent
			height 50px

		textarea
			box-shadow 1px 2px 2px colorGrayLight
			border-color transparent
			height 120px

	.form-group:first-child, .form-group:nth-child(2)
		width 49%

	button
		font-size 1.1rem

.shop-main-banner
	padding-top 100px
	margin-bottom 2rem
	background-size cover
	background-position center
	background-repeat no-repeat

.shop-main-banner-carousel
	padding-top 0px
	margin-bottom 2rem

.sticky-top-banner
	top 85px !important

.main-banner-carousel.owl-carousel
	.owl-dots
		position absolute
		.owl-dot
			span
				width 10px
				height 10px
				background-color rgba(#000, 20%)
			&:hover
				span
					background-color rgba(#000, 70%)
			&.active
				span
					background-color rgba(#000, 90%)
	.owl-nav
		position absolute
		top 50%
		left 0
		right 0
		.owl-prev,
		.owl-next
			absolute top 50%
			margin-top -30px
			color rgba(#000, 40%)
			background-color rgba(#fff, 30%)
			&:hover
				color rgba(#000, 80%)
				background-color rgba(#fff, 50%)
			&:before
				line-height 32px
				font-size 18px
		.owl-prev
			left 15px
			padding-right 2px
		.owl-next
			right 15px
			padding-left 2px

.section-shop
	min-height 80vh

	.shop-menu
		display flex
		justify-content space-between
		align-items center
		height 80px
		margin-bottom 2rem

		.shop-secure-shield
			background-color colorSuccess
			color white
			padding 5px 15px
			height 32px
			position relative

			&:after
				content ''
				position absolute
				top 0
				right -15px
				width 0
				height 0
				border-style solid
				border-width 16px 0 16px 15px
				border-color transparent transparent transparent colorSuccess

		.shop-filters
			display flex
			flex 1
			flex-wrap wrap
			margin 0 30px
			max-width 600px

		.shop-search
			position relative
			flex 1
			border 5px solid colorGrayLight
			border-radius 9px

			input
				padding-left 35px

			.fa
				position absolute
				top 9px
				left 10px
				color colorGray

		.shop-categories
			position relative
			border 5px solid colorGrayLight
			border-radius 9px
			margin-left 1rem
			min-width 200px

		.shop-cart
			button
				font-size 1.1rem
				border none
				padding-left 1rem
				padding-right 1rem

				i
					margin-right 5px

.grid-products
	display grid
	grid-template-columns repeat(auto-fill, minmax(240px, 1fr))
	gap 30px
	margin-bottom 6rem

	.product-card
		.panel
			margin-bottom 0
		img
			border-radius 6px
		h5
			font-size: 1.1rem
			margin-bottom 0.6rem
			word-break break-word
		.price
			font-size 1.1rem
			margin-bottom 0.6rem
			display block

.grid-products-banner
	display grid
	grid-template-columns 240px 1fr
	gap 30px

.product-detail
	display grid
	grid-template-columns 440px 1fr
	column-gap 30px

	.image img
		border-radius 6px

	.product-info
		h3
			word-break break-word

	.price
		font-size 1.1rem
		margin-bottom 0.6rem
		display block

.basket
	display grid
	grid-template-columns 1fr 320px
	column-gap 30px
	align-items flex-start

	.basket-item
		display grid
		grid-template-columns 100px 1fr 300px
		grid-template-areas "image info amounts"
		column-gap 15px
		align-items center
		padding-bottom 8px
		margin-bottom 8px
		border-bottom 1px dashed colorGray

		&:last-child
			border-bottom none

		.basket-item-img
			grid-area image
			img
				border-radius 6px

		.basket-item-info
			grid-area info
			h5
				margin 0

		.basket-item-amounts
			grid-area amounts
			display flex
			justify-content space-between
			align-items center

		.basket-item-remove
			color colorDanger
			font-size 0.8rem

	.basket-summary

		.basket-summary-box
			background-color colorGrayLight
			padding 0.5rem
			margin-bottom 1rem

			> div
				background-color white
				padding 8px 10px
				height inherit

				p
					border-top 1px solid colorGrayLight
					padding 8px 0
					margin 0

					span
						float right

					&.basket-summary-total
						font-size 1.2rem
						font-weight bold

.input-group.input-qty
	display flex
	align-items center

	.input-group-btn
		width auto

	input
		width 45px
		padding 6px
		text-align center
		border-color colorGray

.grid-lateral-banner
	margin-bottom 4rem

@media (max-width 999px)
	.section-shop .shop-menu
		.shop-secure-shield
			font-size 0.8rem
			padding 8px 12px

		.shop-filters
			margin-right 20px
			.shop-categories
				min-width 150px

	// .grid-products-banner
	// 	grid-template-columns 1fr
	// 	.grid-lateral-banner
	// 		grid-row-start 2
	// 		display grid
	// 		grid-template-columns repeat(auto-fill, minmax(240px, 1fr))
	// 		gap 30px
	// 		margin-bottom 6rem

	.basket
		.basket-item
			grid-template-columns 100px 1fr
			grid-template-areas "image info" "amounts amounts"
			row-gap 15px
			padding-bottom 15px
			margin-bottom 15px

@media (max-width 789px)
	.product-detail
		grid-template-columns 1fr 1fr

	.section-shop .shop-menu
		.shop-secure-shield
			display none

		.shop-filters
			margin-left 0px

	.grid-products-banner
		grid-template-columns 1fr
		.grid-lateral-banner
			grid-row-start 2
	.basket
		grid-template-columns 1fr
		row-gap 20px

@media (max-width 570px)
	.grid-products
		grid-template-columns repeat(2, 1fr)
		gap 1rem
		margin-bottom 3rem

		.product-card
			.panel .panel-space
				padding 8px

	.grid-products-banner
		.grid-lateral-banner
			grid-template-columns repeat(2, 1fr)
			margin-bottom 4rem

	.product-detail
		grid-template-columns 0.6fr 1fr
		column-gap 15px

	.section-shop .shop-menu
		display block
		height auto

		.shop-filters
			margin-right 0px
			display block

			.shop-search
				margin 0 0 10px 0

			.shop-categories
				margin 0 0 10px 0

		.shop-cart
			text-align center

@media (max-width 460px)
	.product-detail
		grid-template-columns 1fr

		.image
			margin-bottom 20px


@media (max-width 360px)
	.grid-products
		grid-template-columns 1fr

	.grid-products-banner
		.grid-lateral-banner
			grid-template-columns 1fr
			margin-bottom 2rem